<script setup lang="ts">
  import Flicking from '@egjs/vue3-flicking';
  import type { IDoctor } from '~/EastclinicVueApi';
  // import MainGalleryWrapper from '~/components/ModalGallery/MainGalleryModalWrapper.vue';

  // const emit = defineEmits(['openGallery']);
  const props = defineProps<{ doctor: IDoctor }>();
  const doctorSlider = ref(null);

  const firstVideo = computed(() => {
    if (props.doctor?.content !== undefined) {
      return props.doctor.content.filter((obj) => obj.typeFile === 'video' && obj.type === '720p')[0];
    } else {
      return null;
    }
  });
  const slideItems = computed(() => {
    const result = [];
    if (firstVideo.value) {
      result.push(firstVideo.value);
    }
    if (props.doctor.photos) {
      for (const item in props.doctor.photos['576x576']) {
        result.push(props.doctor.photos['576x576'][item]);
      }
    }
    return result;
  });
  const navItems = computed(() => {
    const result = [];
    if (firstVideo.value) {
      result.push(firstVideo.value);
    }
    if (props.doctor.photos) {
      for (const item in props.doctor.photos['120x120']) {
        result.push(props.doctor.photos['120x120'][item]);
      }
    }
    return result;
  });
  const currentSlide = ref(0);
  const showGalleryModal = ref(false);

  const openGallery = () => {
    showGalleryModal.value = true;
  };
  const prev = () => {
    if (doctorSlider.value) {
      doctorSlider.value.prev();
    }
  };
  const next = () => {
    if (doctorSlider.value) {
      doctorSlider.value.next();
    }
  };
  const switchSlide = (slide: number) => {
    currentSlide.value = slide;
  };

  const flick = ref<Flicking | null>(null);

  const hiddenArrow = ref<'top'|'bottom'|'both'|'none'>('top');

  const updateIndex = () => {
    if (!flick.value) {
      return;
    }
    const length = navItems.value.length;
    if (length <= 3) {
      hiddenArrow.value = 'both';
      return;
    }
    const isTop = flick.value.visiblePanels.some((p) => p.index === 0);
    const isBottom = flick.value.visiblePanels.some((p) => p.index === length - 1);

    if (isTop === false && isBottom === false) {
      hiddenArrow.value = 'none';
      return;
    }
    if (isTop && isBottom) {
      hiddenArrow.value = 'both';
      return;
    }
    if (isTop) {
      hiddenArrow.value = 'top';
      return;
    }
    hiddenArrow.value = 'bottom';
  };

  const currentIndex = ref(0);

  const move = (direction: 'top'|'bottom') => {
    if (!flick.value) { return; }

    const totalPanels = flick.value.panels.length - 1;
    const maxStep = 3;

    if (direction === 'top') {
      const step = Math.min(maxStep, currentIndex.value);
      currentIndex.value = (currentIndex.value - step < 0 || currentIndex.value - step < maxStep) ? 0 : currentIndex.value - step;
      flick.value?.moveTo(currentIndex.value);
    }
    if (direction === 'bottom') {
      const step = Math.min(maxStep, totalPanels - currentIndex.value);
      currentIndex.value = (currentIndex.value + step > totalPanels || totalPanels - (currentIndex.value + step) < maxStep) ? totalPanels : currentIndex.value + step;
      flick.value?.moveTo(currentIndex.value);
    }
  };

  const setZeroIndex = () => {
    if (!flick.value) { return; }
    flick.value.moveTo(0);
    if (navItems.value.length > 3) {
      hiddenArrow.value = 'top';
    }
  };

  const toMMSS = (secs: number) => {
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return `${minutes}:${(seconds < 10) ? '0' + seconds : seconds}`;
  };

  const isVideoMuted = ref(true);

  const duration = ref('');

  const vid = computed<HTMLVideoElement | null>(() => {
    const container = document.getElementsByClassName('carousel__slide--visible');
    return (container.length >= 1) ? container[0].querySelector('video') as HTMLVideoElement : [];
  });

  const videoDuration = () => {
    if (!vid.value) { return; }
    if (!Number.isNaN(vid.value.duration)) {
      const secs = Math.floor(vid.value.duration);
      duration.value = toMMSS(secs);
    }
  };

  let timer: number | null = null;

  const togglePlay = () => {
    if (isVideoMuted.value === false) {
      isVideoMuted.value = true;
    } else {
      isVideoMuted.value = false;
      if (!vid.value) { return; }
      vid.value.pause();
      vid.value.currentTime = 0;
      vid.value.play();
      startTimer();
    }
  };

  const startTimer = () => {
    if (!vid.value) { return; }
    duration.value = toMMSS(Math.floor(vid.value.duration));
    timer = setInterval(() => {
      if (!vid.value) { return; }
      duration.value = toMMSS(Math.floor(vid.value.duration - vid.value?.currentTime));
    }, 1000);
  };

  const play = () => {
    if (!vid.value) { return; }
    vid.value.play();
  };

  const pause = () => {
    if (!vid.value) { return; }
    vid.value.pause();
  };

  watch(currentSlide, (newSlide) => {
    isVideoMuted.value = true;
    if (!flick.value) {
      return;
    }
    flick.value.moveTo(newSlide);
  });

  const showServerScroll = computed(() => {
    if (import.meta.client === false) {
      return 'block';
    }
    return 'none';
  });

  onMounted(() => {
    if (navItems.value.length <= 3) {
      hiddenArrow.value = 'both';
    } else {
      hiddenArrow.value = 'top';
    }
    startTimer();
  });

</script>

<template>
  <div class="single-doctor__gallery">
    <!-- <MainGalleryWrapper v-model:visible="showGalleryModal" :doctor="doctor" /> -->
    <div v-if="navItems.length >= 1" class="desktop-carousel-container">
      <div class="server-scroll">
        <div
          v-for="(item, key) in navItems"
          :key="key"
          class="single-doctor__gallery__click-photos__item"
        >
          <div v-if="item?.typeFile === 'video'" class="single-doctor__gallery__main-video__thumb-wrapper">
            <div class="single-doctor__gallery__playbutton__wrapper">
              <div class="single-doctor__gallery__playbutton">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M14.2717 6.23473C15.6863 7.00399 15.6863 8.99536 14.2717 9.76462L5.73043 14.4094C4.35558 15.157 2.66602 14.1839 2.66602 12.6444L2.66602 3.35494C2.66602 1.81546 4.35558 0.842356 5.73042 1.58999L14.2717 6.23473Z" fill="white"></path>
                </svg>
              </div>
            </div>
            <video
              :src="item?.url"
              class="single-doctor__gallery_main-video_thumb"
            ></video>
          </div>
          <div v-else class="single-doctor__gallery__click-photos__item__img">
            <img
              loading="lazy"
              width="auto"
              height="100%"
              :src="item"
              :alt="doctor.fullname"
              draggable="false"
            >
          </div>
        </div>
      </div>
      <ClientOnly>
        <Flicking
          :key="props.doctor.id"
          ref="flick"
          class="flicking-container"
          :options="{
            align: 'prev',
            circular: false,
            bound: true,
            moveType: ['strict', { count: 4 }],
            horizontal: false,
            renderOnlyVisible: false,
          }"
          :viewport-tag="'div'"
          :camera-tag="'div'"
          :camera-class="''"
          @changed="updateIndex"
          @ready="setZeroIndex"
        >
          <div
            v-for="(item, key) in navItems"
            :key="key"
            class="single-doctor__gallery__click-photos__item"
            :class="{'active': (key === currentSlide)}"
            @click="switchSlide(key)"
          >
            <div v-if="item?.typeFile === 'video'" class="single-doctor__gallery__main-video__thumb-wrapper">
              <div class="single-doctor__gallery__playbutton__wrapper">
                <div class="single-doctor__gallery__playbutton">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14.2717 6.23473C15.6863 7.00399 15.6863 8.99536 14.2717 9.76462L5.73043 14.4094C4.35558 15.157 2.66602 14.1839 2.66602 12.6444L2.66602 3.35494C2.66602 1.81546 4.35558 0.842356 5.73042 1.58999L14.2717 6.23473Z" fill="white"></path>
                  </svg>
                </div>
              </div>
              <video
                :src="item?.url"
                class="single-doctor__gallery_main-video_thumb"
              ></video>
            </div>
            <div v-else class="single-doctor__gallery__click-photos__item__img">
              <img
                loading="lazy"
                width="auto"
                height="100%"
                :src="item"
                :alt="doctor.fullname"
                draggable="false"
              >
            </div>
          </div>
        </Flicking>
        <div
          class="top"
          style="width: 100%; background-color: white; padding-bottom: 8px;"
          :class="{hidden: hiddenArrow === 'top' || hiddenArrow === 'both'}"
        >
          <div class="chevron-container" @click="move('top')">
            <IconChevronDown class="nav-chevron" />
          </div>
        </div>
        <div
          class="bottom"
          style="width: 100%; background-color: white; padding-top: 8px;"
          :class="{hidden: hiddenArrow === 'bottom' || hiddenArrow === 'both'}"
        >
          <div class="chevron-container" @click="move('bottom')">
            <IconChevronDown class="nav-chevron" />
          </div>
        </div>
      </ClientOnly>
    </div>
    <div class="square-slider">
      <Carousel
        ref="doctorSlider"
        v-model="currentSlide"
        :i18n="{
          'ariaNextSlide': 'Следующий слайд',
          'ariaPreviousSlide': 'Предыдущий слайд',
          'ariaNavigateToSlide': 'Открыть слайд {slideNumber}',
          'ariaGallery': 'Галерея',
          'itemXofY': 'Слайд {currentSlide} из {slidesCount}',
          'iconArrowUp': 'Стрелка вверх',
          'iconArrowDown': 'Стрелка вниз',
          'iconArrowRight': 'Стрелка вправо',
          'iconArrowLeft': 'Стрелка влево',
        }"
      >
        <Slide
          v-for="(item, key) in slideItems"
          :key="key"
          class="single-doctor__gallery__images-wrap"
          @click="openGallery"
        >
          <div
            v-if="item.typeFile === 'video'"
            class="single-doctor__gallery__main-photo"
            @move-start="pause"
            @move-end="play"
            @hold-start="pause"
            @hold-end="play"
            @touchstart="pause"
            @touchend="play"
          >
            <video
              class="single-doctor__gallery_main-video"
              playsinline
              autoplay
              :muted="isVideoMuted"
              loop
              :src="item.url"
              @click="togglePlay"
              @loadedmetadata="videoDuration()"
            ></video>
            <div class="single-doctor__gallery__footer-bar" @click="togglePlay">
              <div class="single-doctor__gallery__main-video__button pointer">
                <div class="single-doctor__gallery__main-video__button__wrap">
                  <span v-if="duration !== 'NaN:NaN'" class="text-semibold" style="margin-right: 12px; width: 32px">{{ duration }}</span>
                  <component :is="`IconVolume${isVideoMuted ? 'Off':'On'}`" style="font-size: 24px; margin: 0" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="single-doctor__gallery__main-photo">
            <img
              :src="item"
              loading="lazy"
              width="auto"
              height="100%"
              itemprop="image"
              :alt="doctor.fullname"
              draggable="false"
            >
          </div>
        </Slide>
        <template #addons>
        </template>
      </Carousel>
      <div v-if="navItems.length > 1">
        <div
          class="single-doctor__gallery__back_arrow"
          @click="prev()"
        >
          <div class="rewiews__gallery__navigation">
            <div class="button button__gallery prev">
              <IconBack filled />
            </div>
          </div>
        </div>
        <div
          class="single-doctor__gallery__next_arrow"
          @click="next()"
        >
          <div class="rewiews__gallery__navigation">
            <div class="button button__gallery prev">
              <IconBack style="transform: rotate(180deg)" filled />
            </div>
          </div>
        </div>
      </div>

      <div v-if="slideItems.length > 1" class="carousel-control-container">
        <div
          v-for="i in slideItems.length"
          :key="i"
          :class="`control ${currentSlide === i-1 ? 'active':''}`"
          @click="doctorSlider?.slideTo(i-1);"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.server-scroll {
  @include scroll;
  height: 523px;
  overflow-y: scroll;
  display: v-bind(showServerScroll);
}
.flicking-container {
  height: 523px;
}
.desktop-carousel-container {
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
}
.square-slider {
  position: relative;
  width: 523px;
  height: 523px;
  @media (max-width: 768px) {
    aspect-ratio : 1 / 1;
    width: 100%;
    height: 100%;
  }
}
.single-doctor__gallery {
  display: flex;
  gap: 24px;
  position: relative;
  .v-card__actions {
    padding: 0 !important;
  }
  &__flick {
    border-radius: 15px;
  }
  &__navigation {
    display: flex;
    &__prev, &__next {
      position: absolute;
      top: calc(50% - 13px);
    }
    &__prev {
      left: -20px;
    }
    &__next {
      right: -20px;
    }
  }
  &__images-wrap {
    width: 523px;
    height: 523px;
    background: rgba(0,0,0,.2);
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    &:before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(100px);
      backdrop-filter: blur(100px);
    }
    @media (max-width: 768px) {
      aspect-ratio : 1 / 1;
      width: 100%;
      height: 100%;
    }
  }
  &__main-photo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    position: relative;
    & img, & video {
      height: 100%;
    }
  }
  &__footer-bar {
    position: absolute;
    bottom: 32px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  &__main-video {
    &__wrapper {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      & video {
        height: 100%;
      }
    }

    &__button {

      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
      top: -15px;
      &__wrap {
        background: #00000066;
        color: #fff;
        height: 40px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        /* @media (max-width: 768px) {
          height: 40px;
          width: 127px;
        } */
      }
    }
    &__thumb-wrapper {
      width: 126px;
      height: 126px;
      display: flex;
      justify-content: center;
    }
  }
  &__click-photos {
    position: relative;
    display: inherit;
    &__item {
      display: flex;
      position: relative;
      overflow: hidden;
      border-radius: 24px;
      border: 2px solid transparent;
      cursor: pointer;
      width: 126px;
      height: 126px;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
      img {
        width: 126px !important;
        height: 126px;
      }
      &__img {
        width: 100%;
        height: 100%;
      }
      &.active {
        border: 2px solid #2D7FF9;
      }
      &:hover {
        border: 2px solid #2D7FF9;
      }
    }
  }
  &__playbutton {
    /* fill: linear-gradient(0deg, #9C9C9C 0%, #9C9C9C 100%), rgba(37, 37, 37, 0.55);
    background-blend-mode: overlay, normal;
    backdrop-filter: blur(6px); */
    background-color: #00000066;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__wrapper {
      position: absolute;
      left: 12px;
      top: 12px;
    }
  }
  &__next_arrow, &__back_arrow {
    position: absolute;
    top: calc(50% - 20px);
    width: 32px;
    height: 32px;
    z-index: 1;
    @media (max-width: 768px) {
      display: none;
    }
    & svg {
      position: relative;
      top: 1px;
    }
  }
  &__next_arrow {
    right: 30px;
    & svg {
      left: 2px;
    }
  }
}
.button__gallery {
  &.prev {
    left: 16px;
  }
  &.next {
    right: 16px;
  }
}

// copy from src\components\ClinicPage\PhotoCarousel.vue
.carousel-control-container {
  z-index: 1;
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translate(-50%,0);
  display: flex;
  gap: 8px;
  border-radius: 50px;
  /* background: linear-gradient(0deg, #9C9C9C 0%, #9C9C9C 100%), rgba(37, 37, 37, 0.55);
  background-blend-mode: overlay, normal;
  backdrop-filter: blur(20px); */
  background: #00000066;
  padding: 8px 12px;
  /* display: none;
  @media (max-width: 680px) {
    display: flex;
  } */
}
.control {
  width: 8px;
  height: 8px;
  border-radius: 50px;
  opacity: 0.3;
  background-color: white;
  cursor: pointer;
}
.active {
  opacity: 1;
}
.top {
 z-index: 1;
 position: absolute;
 left: 0px;
 top: 25px;
 transform: translate(0,-50%);
 & .nav-chevron {
  transform: rotate(180deg);
 }
}
.bottom {
 z-index: 1;
 position: absolute;
 right: 0px;
 bottom: -30px;
 transform: translate(0,-50%);
}
.chevron-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  background-color: white;
  cursor: pointer;
  user-select: none;
  &:hover {
    border-radius: 24px;
    background-color: #F2F3F6;
  }
}
.nav-chevron {
  font-size: 24px;
  margin: 0;
}
.hidden {
  display: none;
}
</style>
