<script setup lang="ts">
  import type { ChevronInterface } from '../../EastclinicVueApi';
  import ChevronsSingleDoctor from '../Chevrons/views/ChevronsSingleDoctor.vue';
  import ChevronsDoctorCard from '../Chevrons/views/ChevronsDoctorCard.vue';

  const pageInfoStore = usePageInfoStore();

  interface ChevronCardViewProps {
    chevrons?: ChevronInterface[] | Ref<ChevronInterface>[]
  }
  defineProps<ChevronCardViewProps>();

  const typeDoctorPage = pageInfoStore.pageInfo?.type;
</script>

<template>
  <ChevronsDoctorCard v-if="typeDoctorPage !== 'doctor' && chevrons" :items="chevrons" />
  <ChevronsSingleDoctor v-else-if="chevrons" :items="chevrons" />
</template>

<style lang="scss">
.chevrons {
  display: flex;
  flex-direction: row;
  @media (min-width: 1075px) {
    flex-wrap: wrap;
  }
  &__item {
    border-radius: 10px;
    padding: 3px 8px;
    font-size: 14px;
    background-color: $fields-back-color;
    margin-right: 8px;
    white-space: nowrap;
    margin-bottom: 8px;
    @media(max-width: 680px) {
      font-size: 12px;
    }
    & svg {
      transform: translateY(2px);
    }
  }
  &__other {
    background-color: $secondary-default-color;
    color: $main-color;
  }
  &__item:last-child {
    margin-right: 0;
  }
}
</style>
