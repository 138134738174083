<script setup lang="ts">
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import type { Ref } from 'vue';

  const fixed = ref(false);
  const elemOffset = ref(0);
  const observer = ref({});
  const fixedBlock: Ref<HTMLElement | null> = ref(null);
  // Create a ref to hold the reference to the element
  const fixedToggle = () => {
    if (fixedBlock.value) {
      const elemPos = fixedBlock.value?.getBoundingClientRect();
      const parentPos = fixedBlock.value?.parentElement?.getBoundingClientRect();
      const elemOffsetCurrent = elemPos.height + elemPos.top;
      if (!elemOffset.value && elemOffset.value !== elemOffsetCurrent) {
        elemOffset.value = elemOffsetCurrent + 20; // +20 for paddings (parentPos.height / 100 * 10)
      }
      if (elemOffset.value + Math.abs(parentPos.top) < parentPos.height) {
        fixed.value = false;
      } else {
        fixed.value = true;
      }
    }
  };

  onMounted(() => {
    window.addEventListener('scroll', fixedToggle);
    fixedToggle();
    observer.value = new ResizeObserver((entries) => {
      fixed.value = false;
    });
    observer.value.observe(fixedBlock.value?.parentElement);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('scroll', fixedToggle);
    // this.observer.unobserve(this.$refs["fixed-block"].parentElement);
  });
</script>

<template>
  <div ref="fixedBlock" :class="{'fixed-to-bottom': fixed}" class="fixed-block">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.fixed-block {
  position: fixed;
  &.fixed-to-bottom {
    position: absolute;
    bottom: 0;
  }
}
</style>
