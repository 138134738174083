import revive_payload_client_Q7bYEWa9Cu from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_eowkShTWWK from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QFhOZUg8cz from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_p1FDxfBd99 from "/temp/build/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.29.1_vite@6.0.5_@types+node@22.10.2_jiti@2.4._ztk6u46m7qpgvq36zpkoup57oe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_20QHQ2A339 from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_9N2wWNI6RD from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wibWC5wf7E from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_CCHf0EnjVX from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8oCExFjJTY from "/temp/build/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.7.2_vue@3.5.13_typescript@5.7.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/temp/build/.nuxt/components.plugin.mjs";
import prefetch_client_PmogOaUsLt from "/temp/build/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.2_db0@0.2.1_eslint@8.57.0_ioredis@5.4.2_m_okxqx634fsyjwfkvjlw32zreou/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_ciMKi1y5va from "/temp/build/node_modules/.pnpm/@vueuse+motion@2.2.6_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.7.2_/node_modules/@vueuse/motion/dist/nuxt/runtime/templates/motion.js";
import nuxt_plugin_qILoCydJpV from "/temp/build/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.29.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import nuxt3_plugin_wUKcHKCCCm from "/temp/build/node_modules/.pnpm/vue-yandex-maps@2.1.5_@vue+runtime-core@3.5.13_nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@_ky5o5pw3qzbp5nqaiufqb2fbji/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import vmask_MNloZULcpN from "/temp/build/src/plugins/vmask.ts";
import nuxtServerInit_LTWY2PUaTk from "/temp/build/src/plugins/nuxtServerInit.ts";
import scrollTop_e7v3LLiez9 from "/temp/build/src/plugins/scrollTop.ts";
import sentryInit_client_D9KT86gqsm from "/temp/build/src/plugins/sentryInit.client.ts";
export default [
  revive_payload_client_Q7bYEWa9Cu,
  unhead_eowkShTWWK,
  router_QFhOZUg8cz,
  _0_siteConfig_p1FDxfBd99,
  payload_client_20QHQ2A339,
  navigation_repaint_client_9N2wWNI6RD,
  check_outdated_build_client_wibWC5wf7E,
  chunk_reload_client_CCHf0EnjVX,
  plugin_vue3_8oCExFjJTY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_PmogOaUsLt,
  motion_ciMKi1y5va,
  nuxt_plugin_qILoCydJpV,
  nuxt3_plugin_wUKcHKCCCm,
  vmask_MNloZULcpN,
  nuxtServerInit_LTWY2PUaTk,
  scrollTop_e7v3LLiez9,
  sentryInit_client_D9KT86gqsm
]