<script setup lang="ts">
  const props = defineProps({
    educationDesc: {
      type: String,
      default: ''
    },
    educationUp: {
      type: String,
      default: ''
    }
  });

  // const perPage = ref(2);
  // const open = ref(false);

  const educationBlock = ref<HTMLElement>({});

  const doctorEducation = computed(() => separate(props.educationDesc));
  const doctorEducationUp = computed(() => separate(props.educationUp));

  // Methods
  // const showMore = () => {
  //   perPage.value = doctorEducation.value.length + doctorEducationUp.value?.length;
  //   open.value = true;
  // };
  // const showLess = () => {
  //   perPage.value = 2;
  //   open.value = false;
  //   const element = educationBlock.value;
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };
  interface EducationItem {
    year: string;
    text: string;
  }
  const separate = (text: string): EducationItem[] => {
    const output: EducationItem[] = [];
    if (!text) { return []; }
    if (text.search('<li>') !== -1) {
      const a = text.split('<li>');

      for (const item of a) {
        if (item.search('</li>') !== -1) {
          let splitYear: string[] = [];
          const cleanYear = item.replace(/<ul>|<\/li>|<\/ul>|<p>|<\/p>|Курсы повышения квалификации:|Курсы профессиональной переподготовки:|Профессиональная подготовка:/g, '');
          if (cleanYear.search(' - ') !== -1) {
            splitYear = cleanYear.split(' - ');
          }
          if (cleanYear.search('&ndash;') !== -1) {
            splitYear = cleanYear.split('&ndash;');
          }
          output.push({
            year: splitYear[0],
            text: splitYear[1] ? splitYear[1] : cleanYear
          });
        }
      }
    }
    return output;
  };

  // const limit = computed(() => {
  //   const total = doctorEducation.value.length + doctorEducationUp.value.length;
  //   return (total - perPage.value < 2) ? total : perPage.value;
  // });
</script>

<template>
  <div ref="educationBlock" class="single-doctor__studying">
    <div
      v-for="item in doctorEducation"
      :key="item.year"
      class="single-doctor__studying__item"
    >
      <div class="single-doctor__studying__item__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M14.2173 3.49965C12.7963 2.83345 11.2038 2.83345 9.7828 3.49965L3.0916 6.63664C1.63613 7.31899 1.63614 9.68105 3.0916 10.3634L9.78272 13.5004C11.2037 14.1665 12.7962 14.1665 14.2172 13.5004L20.9084 10.3634C22.3639 9.68101 22.3639 7.31895 20.9084 6.63659L14.2173 3.49965Z" stroke="#878FA2" stroke-width="1.5"></path>
          <path d="M22 8.5V14" stroke="#878FA2" stroke-width="1.5" stroke-linecap="round"></path>
          <path d="M5 11.5V16.6254C5 17.6334 5.5035 18.5772 6.38533 19.0656C7.8537 19.8787 10.204 21 12 21C13.796 21 16.1463 19.8787 17.6147 19.0656C18.4965 18.5772 19 17.6334 19 16.6254V11.5" stroke="#878FA2" stroke-width="1.5" stroke-linecap="round"></path>
        </svg>
      </div>
      <div>
        <p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span class="doctor-education-item-text" v-html="item.text"></span>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <br><span class="doctor-education-item-year" v-html="item.year"></span>
        </p>
      </div>
    </div>
    <div v-if="doctorEducationUp.length">
      <h3 class="doctor-education-up-title mb-4">
        Повышение квалификации
      </h3>
      <div
        v-for="item in doctorEducationUp"
        :key="item.year"
        class="single-doctor__studying__item"
      >
        <div class="single-doctor__studying__item__icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M14.2173 3.49965C12.7963 2.83345 11.2038 2.83345 9.7828 3.49965L3.0916 6.63664C1.63613 7.31899 1.63614 9.68105 3.0916 10.3634L9.78272 13.5004C11.2037 14.1665 12.7962 14.1665 14.2172 13.5004L20.9084 10.3634C22.3639 9.68101 22.3639 7.31895 20.9084 6.63659L14.2173 3.49965Z" stroke="#878FA2" stroke-width="1.5"></path>
            <path d="M22 8.5V14" stroke="#878FA2" stroke-width="1.5" stroke-linecap="round"></path>
            <path d="M5 11.5V16.6254C5 17.6334 5.5035 18.5772 6.38533 19.0656C7.8537 19.8787 10.204 21 12 21C13.796 21 16.1463 19.8787 17.6147 19.0656C18.4965 18.5772 19 17.6334 19 16.6254V11.5" stroke="#878FA2" stroke-width="1.5" stroke-linecap="round"></path>
          </svg>
        </div>
        <div>
          <p>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="doctor-education-item-text" v-html="item.text"></span>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <br><span class="doctor-education-item-year" v-html="item.year"></span>
          </p>
        </div>
      </div>
    </div>

    <!-- <button
      v-if="(doctorEducation?.length + doctorEducationUp?.length) - limit !== 0"
      class="button gray-color medium full-width-mobile text-semibold"
      style="margin-top: 16px;"
      @click.prevent="showMore"
    >
      Показать еще {{ (doctorEducation?.length + doctorEducationUp?.length) - limit }}
    </button>
    <button
      v-if="open"
      style="margin-top: 12px;"
      class="button gray-color medium full-width-mobile text-semibold"
      @click.prevent="showLess"
    >
      Свернуть
    </button> -->
  </div>
</template>

<style scoped lang="scss">
.doctor-education-item-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.doctor-education-item-year {
  color: #666E81;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.doctor-education-up-title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.single-doctor__studying {
  &__item {
    display: flex;
    align-items: flex-start;
    min-height: 70px;
    &__icon {
      display: flex;
      background-color: #F2F3F6;
      border-radius: 10px;
      margin-right: 10px;
      padding: 8px;
    }
  }

}
</style>
