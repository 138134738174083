<script setup lang="ts">
  import type { Speciality } from '~/EastclinicVueApi';
  const props = defineProps<{
    specials?: Speciality[] | undefined,
  }>();

  const mainSpecials = (props.specials) ? props.specials.slice(0, 3).join(' · ') : null;
  const otherSpecials = (props.specials) ? props.specials.slice(3) : [];
  const isOpenSpecials = ref(false);
  const openOtherSpecials = () => {
    isOpenSpecials.value = true;
  };
</script>

<template>
  {{ mainSpecials }}
  <span
    v-if="!isOpenSpecials && otherSpecials.length > 0"
    class="main-color pointer"
    style="text-wrap: nowrap;"
    @click="openOtherSpecials"
  >
    · еще {{ otherSpecials.length }}
  </span>
  <span v-if="isOpenSpecials"> · {{ otherSpecials.join(' · ') }}</span>
</template>
