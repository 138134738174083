export const useReviewsFormStore = defineStore('reviewsFormStore', () => {
  const showModal = ref(false);
  const selectedRating = ref(0);
  const reviewText = ref('');
  const errorText = ref('');
  const reviewStatus = ref<'not-sended'|'negative'|'positive'>('not-sended');
  const doctorId = ref(0);

  const getReviewTextLength = computed(() => {
    return reviewText.value.length;
  });

  const openModal = () => {
    showModal.value = true;
    document.documentElement.style.overflowY = 'hidden';
  };

  const closeModal = () => {
    showModal.value = false;
    selectedRating.value = 0;
    reviewText.value = '';
    reviewStatus.value = 'not-sended';
    document.documentElement.style.overflowY = 'initial';
  };

  const validate = () => {
    clearErrorText();
    if (getReviewTextLength.value > 5000) {
      errorText.value = 'Извините, отзыв слишком длинный';
    }
    if (getReviewTextLength.value < 10) {
      errorText.value = 'Извините, отзыв слишком короткий, добавьте ещё деталей';
    }

    return !!errorText.value;
  };

  const clearErrorText = () => {
    if (errorText.value !== '') {
      errorText.value = '';
    }
  };

  return {
    showModal,
    selectedRating,
    reviewText,
    reviewStatus,
    doctorId,
    getReviewTextLength,
    openModal,
    closeModal,
    clearErrorText,
    validate,
    errorText
  };
});
