<script setup lang="ts">
  import type { DropdownModal } from '#build/components';

  // https://vuejs.org/guide/typescript/composition-api.html#typing-component-template-refs
  const dropdownModalRef = ref<InstanceType<typeof DropdownModal> | null>(null);

  const currentFilterTypeModal = defineModel<string|null>();

  const getTranslationType = computed(() => {
    switch (currentFilterTypeModal.value) {
      case 'useful': return 'Полезные';
      case 'new': return 'Новые';
      case 'low-rated': return 'С низкой оценкой';
      case 'high-rated': return 'С высокой оценкой';
      default: return 'Новые';
    }
  });
  const setSortType = (type: string) => {
    dropdownModalRef.value?.closeModal();
    if (currentFilterTypeModal.value === type) {
      return;
    }
    currentFilterTypeModal.value = type;
  };
</script>

<template>
  <DropdownModal ref="dropdownModalRef" modal-title="Показать">
    <template #default>
      <IconReviewArrows class="icon-arrows" />
      <span class="filter-current-type-text">{{ getTranslationType }}</span>
      <IconChevronDown class="icon-chevron" />
    </template>
    <template #popup>
      <!-- TODO
      <span class="popup-option" @click="setSortType('useful')">Полезные</span>
      TODO -->
      <span class="popup-option" @click="setSortType('new')">Новые</span>
      <span class="popup-option" @click="setSortType('low-rated')">С низкой оценкой</span>
      <span class="popup-option" @click="setSortType('high-rated')">С высокой оценкой</span>
    </template>
    <template #modal>
      <!-- TODO
      <div class="option" @click="clickOnOption('useful')">
        <span class="option-text">Полезные</span>
        <IconChevronDown class="chevron" />
      </div>
      TODO -->
      <div class="option" @click="setSortType('new')">
        <span class="option-text">Новые</span>
        <IconChevronDown class="chevron" />
      </div>
      <div class="option" @click="setSortType('low-rated')">
        <span class="option-text">С низкой оценкой</span>
        <IconChevronDown class="chevron" />
      </div>
      <div class="option" @click="setSortType('high-rated')">
        <span class="option-text">С высокой оценкой</span>
        <IconChevronDown class="chevron" />
      </div>
    </template>
  </DropdownModal>
</template>

<style scoped lang="scss">
.icon-arrows {
  font-size: 24px;
  margin: 0;
}
.filter-current-type-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-left: 8px;
  margin-right: 4px;
}
.icon-chevron {
  font-size: 24px;
  margin: 0;
  color: #878FA2;
}
.popup-option {
  padding: 12px;
  border-radius: 16px;
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-wrap: nowrap;
  &:hover {
    background-color: #F5F6F9;
  }
}
.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  cursor: pointer;
  &:hover > * {
    color: #2D7FF9;
  }
}
.option-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.chevron {
  font-size: 1.5rem;
  transform: rotate(270deg);
  color: #878FA2;
}
</style>
