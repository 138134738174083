<script setup lang="ts">
  interface Props {
    modalTitle?: string
    modalHeight?: string
    backgroundColor?: string
  }

  withDefaults(defineProps<Props>(), {
    modalTitle: '',
    modalHeight: '296px',
    backgroundColor: '#F2F3F6'
  });

  const dropdown = ref(null);

  const isMobile = useMediaQuery('(max-width: 1023px)');
  const showMobileModal = ref(false);
  const showPopup = ref(false);
  const isCloseModal = ref(false);

  const clickOnDropdown = () => {
    if (isMobile.value) {
      showMobileModal.value = true;
      return;
    }
    if (isCloseModal.value) {
      isCloseModal.value = false;
      return;
    }
    showPopup.value = true;
  };

  onClickOutside(dropdown, () => {
    showPopup.value = false;
  });

  defineExpose({
    closeModal: () => {
      showPopup.value = false;
      showMobileModal.value = false;
      isCloseModal.value = true;
    }
  });
</script>

<template>
  <div ref="dropdown" class="dropdown-container" @click="clickOnDropdown">
    <slot></slot>
    <div v-if="showPopup" class="popup-dropdown">
      <div class="popup-dropdown-container">
        <slot name="popup"></slot>
      </div>
    </div>
    <Teleport v-if="showMobileModal" to="body">
      <div class="modal">
        <div class="line"></div>
        <div class="container">
          <div class="title">
            <span class="text">{{ modalTitle }}</span>
            <div class="icon-container" @click="showMobileModal = false">
              <IconClose class="icon" />
            </div>
          </div>
          <slot name="modal"></slot>
        </div>
      </div>
      <div class="overlay" @click.stop="showMobileModal = false"></div>
    </Teleport>
  </div>
</template>

<style scoped lang="scss">
.dropdown-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 14px;
  background-color: v-bind(backgroundColor);
  cursor: pointer;
  user-select: none;
}
.popup-dropdown {
  z-index: 100;
  display: flex;
  position: absolute;
  top: 100%;
  padding-top: 8px;
  left: 0%;
}
.popup-dropdown-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 24px;
  background-color: white;
  box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.12);
}
.modal {
  z-index: 102;
  position: fixed;
  gap: 0.5rem;
  bottom: 0;
  width: 100%;
  height: v-bind(modalHeight);
  background-color: white;
  padding: 12px 20px 32px 20px;
  border-radius: 24px 24px 0px 0px;
  user-select: none;
}
.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.line {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 32px;
  height: 4px;
  border-radius: 3px;
  opacity: 0.2;
  background: #000;
}
.container {
  display: flex;
  flex-direction: column;
}
.title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 12px;
}
.text {
  color: #262633;
  font-family: "Roboto Flex";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.05px;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
</style>
