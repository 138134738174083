<script setup lang="ts">
  import Flicking from '@egjs/vue3-flicking';
  import type { IDoctor } from '~/EastclinicVueApi';

  const props = defineProps<{
    items: [];
    visible: boolean;
    doctor: IDoctor;
  }>();

  const mainSlider = ref<Flicking>();
  const emit = defineEmits(['modalGallery:close']);
  const bars = ref<HTMLDivElement[]>([]);
  const currentTitle = ref('');
  const currentSlide = ref(0);
  const playing = ref(true);
  const mute = ref(false);
  const transition = '0.9s';
  const percent = ref(0);
  const isRunning = ref(false);
  let timer: number | null = null;

  const currentVideo = computed<HTMLVideoElement | null>(() => {
    if (mainSlider.value === undefined) { return null; }
    if (mainSlider.value?.panels === undefined) { return null; }
    const panels = mainSlider.value.panels;
    return (panels[currentSlide.value]) ? panels[currentSlide.value]?.element.querySelector('video') : null;
  });

  const clickOutside = (e: PointerEvent) => {
    if (e?.target?.classList[0] === 'stories__overlay') {
      emit('modalGallery:close');
    }
  };
  const close = () => {
    stopTimer();
    emit('modalGallery:close');
  };
  const init = async () => {
    if (!mainSlider.value) { return; }
    await nextTick();
    currentSlide.value = mainSlider.value.currentPanel.index;
    if (mainSlider.value.panels.length > 1) {
      if (currentVideo.value) {
        currentVideo.value.play();
        setTimeout(() => {
          if (currentVideo.value) {
            startTimer(Math.ceil(currentVideo.value.duration * 1000));
          }
        }, 500);
      } else {
        startTimer();
      }
    }
  };
  const play = () => {
    if (currentVideo.value) {
      currentVideo.value.play();
      playing.value = true;
    }
  };
  const pause = () => {
    if (currentVideo.value) {
      currentVideo.value.pause();
      playing.value = false;
    }
  };
  const muteOn = () => {
    if (currentVideo.value) {
      currentVideo.value.muted = true;
      mute.value = true;
    }
  };
  const muteOff = () => {
    if (currentVideo.value) {
      currentVideo.value.muted = false;
      mute.value = false;
    }
  };

  const slideChange = () => {
    if (!mainSlider.value) { return; }

    if (currentVideo.value) {
      currentVideo.value.pause();
    }
    stopTimer();
    percent.value = 0;
    currentSlide.value = mainSlider.value.currentPanel.index;
    makeWatched();
    makeUnwatched();
    resetBar(currentSlide.value);
    if (currentVideo.value) {
      currentVideo.value.play();
      startTimer(Math.ceil(currentVideo.value.duration * 1000), Math.ceil(currentVideo.value.currentTime * 1000));
    } else {
      startTimer();
    }
  };
  const startTimer = (duration: number = 5000, startFrom: number = 0) => {
    if (!isRunning.value) {
      isRunning.value = true;
      percent.value = startFrom;
      timer = setInterval(() => {
        percent.value += 10;
        if (bars.value[currentSlide.value]) {
          bars.value[currentSlide.value].style.transform = `translateX(-${100 - (percent.value * 100) / duration}%)`;
        }
        if (percent.value === duration) {
          stopTimer();
          next();
        }
      }, 10);
    }
  };
  const stopTimer = () => {
    if (isRunning.value) {
      isRunning.value = false;
      if (timer !== null) {
        clearInterval(timer);
        resetBar(currentSlide.value);
        timer = null;
      }
    }
  };
  const next = () => {
    if (!mainSlider.value) { return; }
    mainSlider.value.next();
  };
  const prev = () => {
    if (!mainSlider.value) { return; }
    mainSlider.value.prev();
  };
  const resetBar = (bar: number = -1) => {
    const i = (bar >= 0) ? bar : currentSlide.value;
    bars.value[currentSlide.value].style.transition = '0s';
    bars.value[i].style.transform = 'translateX(-101%)';
    bars.value[currentSlide.value].style.transition = transition;
  };
  const makeWatched = () => {
    if (bars.value[currentSlide.value].parentElement) {
      for (let i = 0; i < currentSlide.value; i++) {
        bars.value[i].parentElement?.classList.add('watched');
        // resetBar(i);
      }
    }
  };
  const makeUnwatched = () => {
    for (const bar in bars.value) {
      if (currentSlide.value <= +bar) {
        bars.value[+bar].parentElement?.classList.remove('watched');
      }
    }
  };

  const bookingStore = useBookingStore();
  const devScheduleStore = useDevScheduleStore();
  const clinicsStore = useClinicsStore();
  const { pageInfo } = storeToRefs(usePageInfoStore());

  const showBookingForm = () => {
    const doctorId = props.doctor?.id;
    if (!doctorId) {
      return;
    }
    bookingStore.setBookingData({ doctorId });
    bookingStore.setBookingFormBlocks({
      showDoctorBlock: true,
      showClinicBlock: true,
      showScheduleBlock: true
    });

    const schedules = devScheduleStore.schedulesByDoctorId.get(doctorId);

    if (schedules && schedules.length > 0) {
      bookingStore.clinic = clinicsStore.getClinic(schedules[0].clinicId);
      bookingStore.slot = schedules[0].days.find((d) => d.slots.length > 0)?.slots[0].timestamp ?? 0;

      devScheduleStore.maybeDeletedSlot = {
        doctorId,
        clinicId: schedules[0].clinicId,
        day: schedules[0].days.find((d) => d.slots.length > 0)?.day ?? 0,
        timestamp: schedules[0].days.find((d) => d.slots.length > 0)?.slots[0].timestamp ?? 0
      };
    } else {
      return;
    }

    if (pageInfo.value?.type === 'cabinet') {
      bookingStore.bookingOnlyMessage = true;
    } else {
      bookingStore.bookingOnlyMessage = false;
    }

    bookingStore.setShowModalBooking(true);
  };
</script>

<template>
  <teleport to="body">
    <TransitionFade>
      <div
        v-if="visible"
        ref="stories__overlay"
        class="stories__overlay"
        :class="{ active: visible }"
        @click="clickOutside($event)"
      >
        <div class="stories" :class="{ active: visible }">
          <div class="stories__play-area">
            <div class="stories__play-area__header">
              <div v-if="props.items.length > 1" class="stories__progress-bars">
                <div
                  v-for="(item, key) in props.items"
                  :key="key"
                  class="stories__progress-bars__item"
                >
                  <div
                    ref="bars"
                    :class="{'black': item.typeFile === 'video'}"
                    class="stories__progress-bars__item__pass"
                    style=""
                  ></div>
                </div>
              </div>
              <div class="stories__play-area__header__titles" :class="`${props.items.length <= 1 ? 'no-progress-bars':''}`">
                <div>
                  <div class="stories__play-area__header__title doctor-name-text">
                    {{ doctor.fullname }}
                  </div>
                  <div class="stories__play-area__header__desc text-semibold question-text">
                    <!-- {{ currentTitle }} -->
                    {{ items[currentSlide]?.alt }}
                  </div>
                </div>
                <div
                  class="stories__play-area__close close-overlay"
                  @click="close()"
                >
                  <IconClose class="close-overlay" style="height: 30px; width: 30px" />
                </div>
              </div>
              <div
                v-if="currentVideo"
                class="stories__play-area__footer__controls mobile-controls"
              >
                <span
                  style="position: relative; right: -6px"
                  class="icons"
                ></span>
                <ModalGalleryControls
                  :playing="playing"
                  :mute="mute"
                  @player:pause="pause"
                  @player:play="play"
                  @player:mute-on="muteOn"
                  @player:mute-off="muteOff"
                />
              </div>
            </div>
            <div class="carousel-wrapper">
              <div
                ref="stories-carousel"
                class="stories__play-area__main-screen"
              >
                <Flicking
                  ref="mainSlider"
                  class="single-doctor__gallery__flick"
                  :options="{
                    align: 'prev',
                    duration: 200,
                    moveType: ['strict', { count: 1 }],
                    inputType: ['touch', 'mouse'],
                    circular: true,
                    renderOnlyVisible: false,
                  }"
                  :viewport-tag="'div'"
                  :camera-tag="'div'"
                  :camera-class="'stories__gallery__main-camera'"
                  @ready="init"
                  @changed="slideChange"
                  @move-start="pause"
                  @move-end="play"
                  @hold-start="pause"
                  @hold-end="play"
                  @touchstart="pause"
                  @touchend="play"
                >
                  <div
                    v-for="(item, key) in props.items"
                    :key="key"
                    :class="{ active: currentSlide === key }"
                    class="stories__play-area__main-screen__slide"
                  >
                    <div class="loader"></div>
                    <video
                      v-if="item.typeFile === 'video'"
                      ref="videoRefs"
                      style="z-index: 1"
                      playsinline
                      :src="item.url.replace('127.0.0.1:8000', 'eastclinic.ru')"
                      @ended="(mainSlider) ? mainSlider.next() : ''"
                    ></video>
                    <img v-else style="z-index: 1" :src="item" :alt="doctor?.fullname">
                  </div>
                </Flicking>
              </div>
            </div>

            <div class="stories__play-area__footer">
              <div
                v-if="currentVideo"
                class="stories__play-area__footer__controls desktop-controls"
              >
                <ModalGalleryControls
                  :playing="playing"
                  :mute="mute"
                  @player:pause="pause"
                  @player:play="play"
                  @player:mute-on="muteOn"
                  @player:mute-off="muteOff"
                />
              </div>
              <div class="stories__play-area__footer__buttons" @click="close()">
                <a
                  class="player-button outline"
                  href="#"
                  @click.prev="showBookingForm"
                >Записаться на приём</a>
              </div>
            </div>
            <div class="stories__play-area__navs">
              <div
                class="stories__play-area__navs__prev"
                @click="prev"
              >
                <IconDoctorCarouselChevron class="desktop-chevron-left" />
                <!-- <span
                  style="transform: rotate(180deg)"
                  class="icons forward-white"
                ></span> -->
              </div>
              <div
                class="stories__play-area__navs__next"
                @click="next"
              >
                <IconDoctorCarouselChevron class="desktop-chevron-right" />
                <!-- <span class="icons forward-white"></span> -->
              </div>
              <div class="stories__play-area__navs__cover"></div>
              <!--              <div-->
              <!--                  class="stories__play-area__navs__pause-field"-->
              <!--              ></div>-->
            </div>
          </div>
          <div class="stories__gallery">
            <div class="stories__gallery__cards">
              <div class="text-center">
                <!--                <div v-for="(item, key) in videos" :key="`btn-${key}`">-->
                <!--                  <div class="stories__gallery__cards__item">-->
                <!--                    <div class="stories__gallery__cards__item__video">-->
                <!--                      <div class="doctor__top__gallery__playbutton__wrapper">-->
                <!--                        <div class="doctor__top__gallery__playbutton">-->
                <!--                          <span class="icons playbutton"></span>-->
                <!--                        </div>-->
                <!--                      </div>-->
                <!--                      <video-->
                <!--                        playsinline-->
                <!--                        class="doctor__top__gallery_main-video_thumb"-->
                <!--                      ></video>-->
                <!--                    </div>-->

                <!--                    <img loading="lazy" width="100%" alt="">-->
                <!--                    {{ item }}-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </TransitionFade>
  </teleport>
</template>

<style scoped lang="scss">
.carousel-wrapper {
  width: 100%;
}
.stories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  @media (max-width: 1080px) {
    width: 90%;
  }
  @media (max-width: 680px) {
    width: 100%;
    height: 100%;
  }
  &.active {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }
  &__overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    &.active {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
  &__progress-bars {
    display: flex;
    padding: 16px 0 12px;
    justify-content: center;
    align-items: center;
    &__item {
      height: 2px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 2px;
      position: relative;
      overflow: hidden;
      &.watched {
        background-color: #fff;
      }
      &__pass {
        width: 100%;
        position: absolute;
        left: 0;
        height: 2px;
        background-color: #fff;
        //transition: .9s;
        transform: translateX(-100%);
      }
      &:not(:last-child) {
        margin-right: 3px;
      }
    }
  }
  &__play-area {
    display: flex;
    height: 100%;
    max-height: 80vh;
    max-width: 1080px;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    @media (max-width: 680px) {
      max-height: none;
      border-radius: 0;
    }
    &__close {
      cursor: pointer;
      //position: absolute;
      z-index: 501;
      right: 16px;
      top: 30px;
    }
    &__header {
      position: absolute;
      color: white;
      top: 0;
      left: 0;
      z-index: 500;
      padding: 0 16px 22px;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      &__titles {
        display: flex;
        justify-content: space-between;
      }
      &__title {
        font-size: 14px;
      }
      &__desc {
        font-size: 18px;
      }
    }
    &__main-screen {
      max-height: 608px;
      max-width: 1080px;
      position: relative;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: flex;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      @media (max-width: 1080px) {
        width: 100%;
      }
      @media (max-width: 680px) {
        max-height: none;
        height: 100%;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      //justify-content: center;
      //flex-wrap: wrap;
      //position: relative;
      &__slide {
        scroll-snap-align: start;
        background-color: rgba(0, 0, 0, 0.4);
        background-position: center;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        z-index: 1000;
        &::before {
          display: block;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: blur(100px);
          z-index: -1;
          //filter: blur(5.5rem);
        }
        & img {
          height: 100%;
          max-width: 100%;
          object-fit: contain;
          @media (max-width: 680px) {
            width: 100%;
          }
        }
        & video {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    &__footer {
      position: absolute;
      bottom: 0;
      z-index: 1000;
      padding: 0 16px 16px 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.7) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      @media (max-width: 680px) {
        padding: 0 20px 16px 20px;
      }
      &__controls {
        align-items: center;
        &.desktop-controls {
          display: flex;
          @media (max-width: 680px) {
            display: none;
          }
        }
        &.mobile-controls {
          display: none;
          justify-content: end;
          @media (max-width: 680px) {
            display: flex;
          }
        }
        & .icons {
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 8px;
          }
        }
        & .volumeon,
        & .volumeoff {
          width: 32px;
          height: 32px;
        }
        & .playbutton,
        & .pausebutton {
          width: 21px;
          height: 21px;
        }
      }
      &__buttons {
        @media (min-width: 680px) {
          display: flex;
          justify-content: end;
          width: 100%;
        }
        @media (max-width: 680px) {
          width: 100%;
        }
        & .player-button {
          padding: 14px 65px;
          @media (max-width: 680px) {
            display: block;
            padding: 14px 0;
            text-align: center;
          }
        }
      }
    }
    &__navs {
      z-index: 200;
      &__next {
        cursor: pointer;
        position: absolute;
        height: 100%;
        right: 0;
        width: 30%;
        transition: 0.2s;
        opacity: 0;
        background: linear-gradient(
          -90deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        @media (hover: hover) {
          &:hover {
            transition: 0.2s;
            opacity: 1;
          }
        }
        & .icons {
          position: absolute;
          top: calc(50% - 20px);
          right: 16px;
        }
      }

      &__prev {
        cursor: pointer;
        position: absolute;
        height: 100%;
        left: 0;
        width: 30%;
        opacity: 0;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        @media (hover: hover) {
          &:hover {
            transition: 0.2s;
            opacity: 1;
          }
        }
        & .icons {
          position: absolute;
          top: calc(50% - 20px);
          left: 16px;
        }
      }
      &__pause-field {
        position: absolute;
        right: 30%;
        width: 40%;
        height: 100%;
      }
      &__cover {
        display: none;
        @media (max-width: 680px) {
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          height: 180px;
          width: 100%;
        }
      }
    }
  }
  &__gallery {
    height: 80px;
    margin-top: 16px;
    @media (max-width: 680px) {
      position: absolute;
      z-index: 300;
      bottom: 90px;
      width: 100%;
      margin-left: 35px;
    }
    &__main-camera {
      //justify-content: center;
    }
    &__cards {
      display: flex;
      gap: 16px;
      cursor: pointer;
      &.v-card__actions {
        padding: 0 !important;
      }
      @media (max-width: 1080px) {
      }

      &__item {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 15px;
        border: 2px solid transparent;
        margin-right: 4px;
        @media (max-width: 1080px) {
        }
        @media (max-width: 1080px) {
        }
        &__video {
          position: relative;
          display: flex;
          justify-content: center;
          height: 100%;
          & video {
            height: 100%;
          }
        }
        & img {
          height: 100%;
          object-fit: cover;
        }
      }
      &__item.v-slide-item--active {
        border: 2px solid #fff;
      }
    }

    &__item {
      display: flex;
      position: relative;
      overflow: hidden;
      border-radius: 15px;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}
.desktop-chevron-right {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(-50%, 0);
  font-size: 32px;
  margin: 0px;
  color: white;
  transform: rotate(180deg);
  @media (max-width: 680px) {
    display: none;
  }
}
.desktop-chevron-left {
  position: absolute;
  left: 28px;
  top: 50%;
  transform: translate(-50%, 0);
  font-size: 32px;
  margin: 0px;
  color: white;
  @media (max-width: 680px) {
    display: none;
  }
}
.question-text {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.doctor-name-text {
  color: #F2F3F6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 4px;
}
.no-progress-bars {
  margin-top: 16px;
}
</style>
