<script setup lang="ts">
  const reviewsFormStore = useReviewsFormStore();
</script>

<template>
  <div class="positive-container">
    <div class="title-container">
      <div class="close-container" @click="reviewsFormStore.closeModal">
        <IconClose class="icon-close" />
      </div>
    </div>
    <div class="positive-content">
      <div class="emoji"></div>
      <span class="text-bold">Спасибо за <br> Ваше доверие!</span>
      <span class="text-secondary">Отзывы мотивируют нас делать свою работу ещё лучше</span>
    </div>
    <div class="moderation-container">
      <IconShieldStar filled class="icon-shield" />
      <div class="moderation">
        <span class="moderation-text-bold">Отзыв отправлен на модерацию</span>
        <span class="moderation-text-secondary">В течение дня отзыв будет опубликован</span>
      </div>
    </div>
    <button type="button" class="positive-button" @click="reviewsFormStore.closeModal">
      Спасибо и Вам!
    </button>
  </div>
</template>

<style scoped lang="scss">
.positive-container {
  display: flex;
  flex-direction: column;
}
.title-container {
  display: flex;
  justify-content: end;
}
.close-container {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.positive-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.emoji {
  width: 120px;
  height: 120px;
  background: url(~/assets/img/reviews/positive.png)
}
.text-bold {
  max-width: 365px;
  margin-top: 24px;
  margin-bottom: 8px;
  color: #262633;
  text-align: center;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.text-secondary {
  text-align: center;
  max-width: 273px;
  color: #666E81;
  font-feature-settings: 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.moderation-container {
  display: flex;
  align-items: start;
  gap: 10px;
  margin-top: 40px;
  padding: 12px;
  border-radius: 15px;
  background-color: #F5F6F9;
}
.icon-shield {
  font-size: 24px;
  margin: 0;
}
.moderation {
  display: flex;
  flex-direction: column;
}
.moderation-text-bold {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.moderation-text-secondary {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.positive-button {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 260px;
  height: 56px;
  padding: 15px 16px;
  border-radius: 15px;
  background-color: #2D7FF9;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
</style>
