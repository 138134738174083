<script setup lang="ts">
  import ModalGallery from '~/components/ModalGallery/ModalGallery.vue';
  import type { IDoctor } from '~/EastclinicVueApi';

  const props = defineProps({
    items: {
      type: [Object, Array],
      required: true
    }
  });
  const visible = defineModel<boolean>('visible');

  const images = props.items.map((obj) => obj.image.img);

  const { doctorsCacheMap } = storeToRefs(useDoctorsStore());

  const doctor = computed<IDoctor|undefined>(() => {
    return doctorsCacheMap.value.values().next().value;
  });

  const close = () => {
    visible.value = false;
  };
</script>

<template>
  <ModalGallery
    :visible="visible ?? false"
    :items="images"
    :doctor="doctor"
    @modal-gallery:close="close"
  />
</template>
