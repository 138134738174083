<script setup lang="ts">
  const props = defineProps({
    items: Array
  });
</script>

<template>
  <div
    v-for="(chevron, key) in props.items"
    :key="key"
    class="single-doctor__main-info__item"
  >
    <img v-if="chevron.type !== 'experience'" :src="`/images/doctor/${chevron.icon}.svg`">
    <div v-if="chevron.type !== 'experience'">
      <div class="text-semibold">
        {{ chevron.title }}
      </div>
      <div
        v-if="chevron.description"
        class="annotation relative d-inline"
      >
        {{ chevron.description }}
      </div>
    </div>
  </div>
</template>
