import { API_URL, BASE_URL } from '../../config';
import { postToServer, getToServer } from '../../util/UseFetchToServer';
import type { IReview, INewReview, IReviewsResponse, IReviewsRequest } from '../../index';

export default class ReviewsService {
//

  public async fetchReviews(request:IReviewsRequest) {
    // prepare legacy data
    const res:IReviewsResponse = await getToServer(BASE_URL + API_URL + '/reviews', request) as IReviewsResponse;
    // if (res?.data) {
    //
    // }
    return res;
  }

  public async saveNewReview(reviewData:INewReview) {
    return await postToServer(BASE_URL + API_URL + '/reviews', reviewData) as {message?:string, error?:string, ok:boolean};
    // return res;
  }
}
