import { API_URL, BASE_URL } from '~/EastclinicVueApi/config';
import { postToServer, getToServer, putToServer } from '~/EastclinicVueApi/util/UseFetchToServer';
import type { CommentRequest, CommentResponse, CommentBlockRequest } from '~/EastclinicVueApi';

export default class CommentsService {
  public async getCommentByDoctorId(request:CommentRequest) {
    const res = await getToServer(BASE_URL + API_URL + '/comments/', request) as {ok: boolean, data: CommentResponse[]};

    return res;
  }

  public async createComment(request:CommentRequest) {
    const res = await postToServer(BASE_URL + API_URL + '/admin/comments/', request) as {ok: boolean, data: CommentResponse};

    return res;
  }

  // public async updateComment(request:CommentRequest) {
  //   const res:CommentResponse = await putToServer(BASE_URL + API_URL + `/admin/comments/${request.id}`, request) as CommentResponse;

  //   return res;
  // }

  public async blockComment(request:CommentBlockRequest) {
    const res = await putToServer(BASE_URL + API_URL + `/admin/comments/${request.comment_id}/block`, request) as {ok: boolean, data: CommentResponse};

    return res;
  }
}
