<script lang="ts" setup>
  const props = defineProps({
    interview: { type: Array, required: true },
    name: String,
    image: String
  });
</script>

<template>
  <div class="doctor__dialogs">
    <div class="doctor__dialogs__question">
      <div
        v-for="(item, key) in props.interview"
        :key="key"
      >
        <div class="doctor__dialogs__question-text">
          <div class="doctor__dialogs__question-text__body">{{item.question}}</div>
          <!-- <div class="doctor__dialogs__question-text__icon">
            <span class="icons woman"></span>
            <IconWoman />
          </div> -->
        </div>
        <div class="doctor__dialogs__question-answer">
          <div class="doctor__dialogs__question-answer__icon">
            <div class="doctor__quote__image small">
              <img :src="image" :alt="name">
            </div>
          </div>
          <div v-html="item.answer" class="doctor__dialogs__question-answer__text">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
