<script setup lang="ts">
  import type { DropdownModal } from '#build/components';
  import type { TPlatform } from '~/EastclinicVueApi';

  interface Props {
    platformCountMap: Map<string, number>
  }

  interface Platform {
    name: 'AllPlatforms'|'EastClinic'|'YandexMaps'|'GoogleMaps'|'ProDoctor'|'SberHealth'|'NaPopravku'
    text: string
  }

  const props = defineProps<Props>();

  const platforms: Platform[] = [
    {
      name: 'AllPlatforms',
      text: 'Со всех площадок'
    },
    {
      name: 'EastClinic',
      text: 'На сайте Ист Клиники'
    },
    {
      name: 'YandexMaps',
      text: 'Яндекс'
    },
    {
      name: 'GoogleMaps',
      text: 'Google'
    },
    {
      name: 'ProDoctor',
      text: 'ПроДокторов'
    },
    {
      name: 'SberHealth',
      text: 'СберЗдоровье'
    },
    {
      name: 'NaPopravku',
      text: 'НаПоправку'
    }
  ];

  // https://vuejs.org/guide/typescript/composition-api.html#typing-component-template-refs
  const dropdownModalRef = ref<InstanceType<typeof DropdownModal> | null>(null);

  const currentFilterPlatformModel = defineModel<TPlatform|null>();

  const getTranslationCurrentPlatform = computed(() => {
    switch (currentFilterPlatformModel.value) {
      case 'AllPlatforms': return 'Со всех площадок';
      case 'EastClinic': return 'На сайте Ист Клиники';
      case 'YandexMaps': return 'Яндекс';
      case 'GoogleMaps': return 'Google';
      case 'ProDoctor': return 'ПроДокторов';
      case 'SberHealth': return 'СберЗдоровье';
      case 'NaPopravku': return 'НаПоправку';
      default: return 'Со всех площадок';
    }
  });

  const clickOnPlatform = (platform: Platform) => {
    dropdownModalRef.value?.closeModal();
    if (currentFilterPlatformModel.value === platform.name) {
      return;
    }
    const reviewsCount = props.platformCountMap.get(platform.name) ?? 0;
    if (reviewsCount === 0) {
      return;
    }
    if (platform.name === 'AllPlatforms') {
      currentFilterPlatformModel.value = null;
      return;
    }
    currentFilterPlatformModel.value = platform.name;
  };
</script>

<template>
  <DropdownModal ref="dropdownModalRef" modal-title="Выберите площадку" modal-height="600px">
    <template #default>
      <component :is="`IconReview${currentFilterPlatformModel || 'AllPlatforms'}`" filled class="icon-platform" />
      <span class="filter-current-platform-text">{{ getTranslationCurrentPlatform }}</span>
      <IconChevronDown class="icon-chevron" />
    </template>
    <template #popup>
      <div
        v-for="platform in platforms"
        :key="platform.name"
        class="popup-option"
        @click="clickOnPlatform(platform)"
      >
        <component :is="`IconReview${platform.name}`" filled class="icon-platform" />
        <div class="option-text-container">
          <span class="option-bold-text">{{ platform.text }}</span>
          <span class="reviews-count">{{ platformCountMap.get(platform.name) ?? 0 }} отзывов</span>
        </div>
      </div>
    </template>
    <template #modal>
      <div class="modal-container">
        <div class="modal-text">
          Для вашего удобства мы собрали все отзывы со всех площадок от негативных до положительных.
        </div>
        <div
          v-for="platform in platforms"
          :key="platform.name"
          class="option-container"
          @click="clickOnPlatform(platform)"
        >
          <div class="option">
            <component :is="`IconReview${platform.name}`" v-if="platform.name !== 'EastClinic'" filled class="icon-platform" />
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4539 17.8863C17.3001 18.7881 15.9648 19.4295 14.5396 19.7662C13.1145 20.103 11.6333 20.1272 10.1979 19.8372C8.0477 19.4982 6.0478 18.5245 4.4547 17.0412C2.8616 15.5578 1.74795 13.6324 1.25659 11.5118C0.765221 9.3912 0.918546 7.17218 1.69689 5.13933C2.47524 3.10647 3.84312 1.35248 5.62509 0.102325C4.48643 1.02884 3.57005 2.19892 2.94343 3.52645C2.3168 4.85398 1.99589 6.30514 2.0043 7.7731C2.0043 12.3693 5.6407 13.6023 7.2326 13.6023C9.57363 13.6023 13.5378 11.4407 16.347 8.6939C15.1979 9.839 13.9526 10.8833 12.6248 11.8153C6.37422 16.1618 13.1164 21.4759 18.4539 17.8863Z" fill="url(#paint0_linear_20223_15469)"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.6228 16.8894L19.2152 17.3112C18.9703 17.5282 18.7094 17.7264 18.4348 17.9043C13.1129 21.4938 6.37071 16.1797 12.6213 11.8098C13.9491 10.8778 15.1944 9.8335 16.3435 8.68841C16.8468 8.20087 17.3161 7.67942 17.7481 7.12772L18.0446 6.75316C18.3464 6.35211 18.6227 5.9325 18.8718 5.49681C19.0008 5.26204 19.1154 5.01969 19.2152 4.77109C19.2854 4.59161 19.34 4.41213 19.3946 4.24826C19.669 3.1786 19.6063 2.05033 19.2152 1.01764C19.3424 1.17672 19.4596 1.34351 19.5663 1.51706C19.6053 1.57949 19.6912 1.74336 19.699 1.76677C21.3221 5.30172 15.9143 10.2803 14.7672 11.404C14.2027 11.972 13.7196 12.6154 13.3314 13.3158C12.8085 14.4083 12.6056 15.5944 13.1987 16.3826C13.6591 16.9913 15.8424 19.1221 19.6228 16.8894Z" fill="url(#paint1_linear_20223_15469)"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5743 2.88727C19.5818 3.02763 19.5818 3.16829 19.5743 3.30865C19.5798 3.31814 19.5827 3.32891 19.5827 3.33987C19.5827 3.35083 19.5798 3.36159 19.5743 3.37108C19.5707 3.49972 19.555 3.62774 19.5275 3.75345C19.5275 3.90172 19.4651 4.05778 19.426 4.21385C19.387 4.36992 19.3168 4.5572 19.2466 4.73668C19.1468 4.98528 19.0322 5.22763 18.9032 5.4624C18.6541 5.89809 18.3778 6.3177 18.076 6.71875L17.7795 7.09332C17.3475 7.64501 16.8755 8.18281 16.3722 8.67036C13.5162 11.4562 9.61712 13.6014 7.22927 13.6014C5.66859 13.6014 2.00096 12.5777 2.00098 7.77222C2.00098 6.30423 2.26018 4.96601 2.9401 3.52557C3.56673 2.19804 4.4831 1.02796 5.62177 0.101445L5.75443 0C4.81021 0.944215 1.20503 5.53263 5.11454 9.43435C5.74124 10.0504 6.51852 10.4913 7.36882 10.7131C8.21912 10.9349 9.11272 10.9299 9.96047 10.6985C14.4474 9.74649 19.3246 4.5494 19.3246 1.83381C19.3207 1.54353 19.3134 1.28068 19.2139 1.00794C19.2139 1.00794 19.251 1.0841 19.29 1.18555C19.3291 1.28699 19.5171 1.62825 19.493 1.74373C19.5511 2.00036 19.5597 2.21683 19.5743 2.47953C19.6133 2.59658 19.5743 2.74681 19.5743 2.88727Z" fill="url(#paint2_linear_20223_15469)"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.553 6.11739C10.3711 6.79674 10.0089 7.41428 9.50473 7.90467C9.0006 8.39507 8.3733 8.74008 7.68918 8.90321C6.84175 8.63908 6.12954 8.05703 5.70196 7.27917C5.27438 6.50131 5.16466 5.58808 5.39582 4.73107C5.62698 3.87406 6.18106 3.13988 6.94181 2.68254C7.70255 2.2252 8.61086 2.08025 9.47616 2.2781C9.98433 2.76206 10.3526 3.37411 10.5421 4.04979C10.7316 4.72546 10.7354 5.43974 10.553 6.11739Z" fill="url(#paint3_linear_20223_15469)"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8945 6.49288C11.6553 7.3696 11.0782 8.11572 10.2898 8.5677C9.50138 9.01969 8.56594 9.14067 7.68848 8.90413C8.37245 8.74192 8.9478 8.39794 9.4524 7.90854C9.95701 7.41914 10.32 6.80251 10.5031 6.12382C10.6862 5.44512 10.6825 4.72958 10.4924 4.05281C10.3023 3.37604 9.98509 2.76319 9.47546 2.27902C10.3542 2.51833 11.1023 3.09623 11.5557 3.88609C12.0091 4.67595 12.131 5.61335 11.8945 6.49288Z" fill="url(#paint4_linear_20223_15469)"></path>
              <defs>
                <linearGradient
                  id="paint0_linear_20223_15469"
                  x1="7.07653"
                  y1="18.8618"
                  x2="13.4519"
                  y2="3.30953"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1F59EE"></stop>
                  <stop offset="0.479167" stop-color="#01B3FF"></stop>
                </linearGradient>
                <linearGradient
                  id="paint1_linear_20223_15469"
                  x1="13.5623"
                  y1="18.0216"
                  x2="21.117"
                  y2="6.51659"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1F59EE"></stop>
                  <stop offset="0.479167" stop-color="#01B3FF"></stop>
                </linearGradient>
                <linearGradient
                  id="paint2_linear_20223_15469"
                  x1="8.14177"
                  y1="12.7988"
                  x2="11.3392"
                  y2="1.29367"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1F59EE"></stop>
                  <stop offset="0.479167" stop-color="#01B3FF"></stop>
                </linearGradient>
                <linearGradient
                  id="paint3_linear_20223_15469"
                  x1="7.16584"
                  y1="8.50719"
                  x2="9.44057"
                  y2="3.40592"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1F59EE"></stop>
                  <stop offset="0.479167" stop-color="#01B3FF"></stop>
                </linearGradient>
                <linearGradient
                  id="paint4_linear_20223_15469"
                  x1="9.19798"
                  y1="8.62458"
                  x2="11.8188"
                  y2="3.9489"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1F59EE"></stop>
                  <stop offset="0.479167" stop-color="#01B3FF"></stop>
                </linearGradient>
              </defs>
            </svg>
            <div class="option-text-container">
              <span class="option-bold-text">{{ platform.text }}</span>
              <span class="reviews-count">{{ platformCountMap.get(platform.name) ?? 0 }} отзывов</span>
            </div>
          </div>
          <IconChevronDown class="chevron" />
        </div>
      </div>
    </template>
  </DropdownModal>
</template>

<style scoped lang="scss">
.icon-platform {
  font-size: 24px;
  margin: 0;
}
.filter-current-platform-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-left: 8px;
  margin-right: 4px;
}
.icon-chevron {
  font-size: 24px;
  margin: 0;
  color: #878FA2;
}
.popup-option {
  display: flex;
  align-items: start;
  gap: 12px;
  padding: 12px;
  border-radius: 16px;
  text-wrap: nowrap;
  &:hover {
    background-color: #F5F6F9;
  }
}
.option-text-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.option-bold-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.reviews-count {
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.modal-container {
  display: flex;
  flex-direction: column;
}
.modal-text {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}
.option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  cursor: pointer;
  &:hover > * {
    color: #2D7FF9;
  }
}
.option {
  display: flex;
  align-items: start;
  gap: 12px;
  border-radius: 16px;
  text-wrap: nowrap;
}
.chevron {
  font-size: 1.5rem;
  transform: rotate(270deg);
  color: #878FA2;
}
</style>
