<template>
  <div class="skeleton-container">
    <div class="skeleton-content-container">
      <div class="skeleton gallery"></div>
      <div>
        <div class="skeleton skeleton-name"></div>
        <div class="skeleton border-15" style="width: 225px; height: 16px"></div>
      </div>
      <div class="skeleton border-15" style="width: 100%; height: 72px"></div>
      <div v-for="i in 3" :key="i" class="skeleton border-15" style="width: 373px; height: 28px"></div>
      <div class="calendar-container calendar-mobile">
        <div class="skeleton border-15" style="width: 208px; height: 26px"></div>
        <div class="skeleton border-15" style="width: 100%; height: 78px"></div>
        <div class="skeleton border-15" style="width: 100%; height: 128px"></div>
        <div class="slots-container">
          <div v-for="i in 2" :key="i" class="skeleton border-12" style="width: 60px; height: 38px"></div>
        </div>
      </div>
      <div v-for="i in 5" :key="i" class="skeleton border-15" style="width: 100%; height: 92px"></div>
      <div class="skeleton border-15" style="width: 100%; height: 1067px"></div>
    </div>
    <div class="calendar-container calendar-desktop">
      <div class="skeleton border-15" style="width: 208px; height: 26px"></div>
      <div class="skeleton border-15" style="width: 100%; height: 78px"></div>
      <div class="skeleton border-15" style="width: 100%; height: 128px"></div>
      <div class="slots-container">
        <div v-for="i in 2" :key="i" class="skeleton border-12" style="width: 60px; height: 38px"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.skeleton-container {
  display: flex;
  gap: 76px;
  /* justify-content: space-between; */
}
.skeleton {
  background: linear-gradient(90deg, #f0f0f0, #e0e0e0, #f0f0f0) 0 0/ 100vh 100% fixed;
  animation: bgslide 6s infinite linear
}

@keyframes bgslide {
  to {
    background-position: 100vw 0
  }
}
.border-15 {
  border-radius: 15px;
}
.border-12 {
  border-radius: 12px;
}
.gallery {
  display: flex;
  width: 100%;
  height: 445px;
  border-radius: 30px;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 380px;
  height: 366px;
  @media (max-width: 1023px) {
    width: 100%;
    border-top: 1px solid #E5E5E7;
    border-bottom: 1px solid #E5E5E7;
    padding: 24px 0px;
  }
  @media (min-width: 1023px) {
    padding: 24px;
    border-radius: 30px;
    border: 1px solid #E5E5E7;
    background-color: white;
    box-shadow: 0px 6px 34px 0px rgba(0, 0, 0, 0.10);
  }
}
.calendar-desktop {
  @media (max-width: 1023px) {
    display: none;
  }
}
.calendar-mobile {
  @media (min-width: 1023px) {
    display: none;
  }
}
.slots-container {
  display: flex;
  gap: 8px;
}
.skeleton-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 672px;
  gap: 24px;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
}
.skeleton-name {
  width: 373px;
  height: 28px;
  margin-bottom: 4px;
  border-radius: 15px;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
</style>
