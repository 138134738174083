import { CommentsService, type CommentBody, type CommentRequest, type CommentResponse, type IDoctor } from '~/EastclinicVueApi';

export const useDoctorsStore = defineStore('doctorsStore', () => {
  const doctorsCacheMap = ref<Map<number, IDoctor>>(new Map());

  const count = ref(0);
  const mainGroupCount = ref(0);
  const doctorInfoEl = ref(null);
  const doctorReviewsCount = ref(0);
  const lastGroup = ref('');

  const doctorInfoIsVisible = useElementVisibility(doctorInfoEl);

  const setCount = (countDoctors:number) => {
    count.value = countDoctors;
  };

  const doctorsCommentsMap = ref<Map<number, CommentResponse>>(new Map());

  const fetchDoctorsComments = async (ids: number[]) => {
    const commentsService = new CommentsService();

    const request: CommentRequest = {
      commentable_id: ids,
      commentable_type: 'doctor'
    };
    try {
      const res = await commentsService.getCommentByDoctorId(request);

      if (!res.ok) {
        throw new Error('Ошибка');
      }
      for (const commentRes of res.data) {
        doctorsCommentsMap.value.set(commentRes.commentable_id, commentRes);
      }
    } catch (err) {}
  };

  const tryParseComment = (doctorId: number|undefined) => {
    if (!doctorId) {
      return undefined;
    }
    const comment = doctorsCommentsMap.value.get(doctorId)?.body;
    if (!comment) {
      return undefined;
    }
    return JSON.parse(comment) as CommentBody;
  };

  const tryParseBlockId = (doctorId: number|undefined) => {
    if (!doctorId) {
      return undefined;
    }
    const blockId = doctorsCommentsMap.value.get(doctorId)?.block;
    if (!blockId) {
      return undefined;
    }
    return JSON.parse(blockId).user_id as number;
  };

  return {
    doctorsCacheMap,
    count,
    doctorInfoEl,
    doctorInfoIsVisible,
    doctorReviewsCount,
    lastGroup,
    mainGroupCount,
    doctorsCommentsMap,
    setCount,
    fetchDoctorsComments,
    tryParseComment,
    tryParseBlockId
  };
});
