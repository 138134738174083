<script setup lang="ts">
  import '@egjs/vue3-flicking/dist/flicking.css';
  import { AdminService, type UserResponse } from './EastclinicVueApi';

  const pageInfoStore = usePageInfoStore();
  const pageInfo = computed(() => pageInfoStore.pageInfo);

  const { currentRoute } = useRouter();

  useSeoMeta({
    title: () => pageInfoStore.pageInfo?.longtitle ? pageInfoStore.pageInfo?.longtitle + ' - Ист Клиника' : 'Ист Клиника',
    ogTitle: () => pageInfoStore.pageInfo?.longtitle ? pageInfoStore.pageInfo?.longtitle + ' - Ист Клиника' : 'Ист Клиника',
    description: () => pageInfo.value?.description,
    ogDescription: () => pageInfo.value?.description
  });

  useScript({
    src: 'https://eastclinic.ru/assets/scripts/calltouch.js?v=335',
    key: 'calltouch'
  });

  useScriptGoogleTagManager({
    id: 'AW-10785850902',
    key: 'gtag',
    scriptOptions: {
      // load after 3 seconds on client
      trigger: new Promise((resolve) => setTimeout(resolve, 3000))
    }
  });

  const { onLoaded } = useYandexMetrika();

  onLoaded((ym) => {
    watch(currentRoute, (newRoute) => {
      ym.recordUrl(newRoute.path);
    });
  });

  const userStore = useUserStore();
  const adminService = new AdminService();

  const me = async () => {
    if (useContext() !== 'operator') {
      return true;
    }
    const res = await adminService.me();
    if (res.ok) {
      userStore.user = res as UserResponse;
    }
    return true;
  };

  await useLazyAsyncData('me', me, {
    server: false
  });
 </script>

<template>
  <div>
    <NuxtLoadingIndicator color="#2a76e8" :height="4" :throttle="0" />
    <NuxtLayout>
      <div>
        <NuxtPage class="global-wrapper" />
      </div>
    </NuxtLayout>
    <ClientOnly>
      <CookieBanner />
    </ClientOnly>
  </div>
</template>

<style>
.layout-enter-active,
.layout-leave-active,
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
  /* transition: all 0.6s; */
}
.layout-enter-active,
.layout-leave-active,
.page-enter-from,
.page-leave-to {
  opacity: 0.33;
}
</style>
