<script setup lang="ts">
  interface Props {
    text: string
    maxWords: number
  }

  const props = defineProps<Props>();

  const splitText = (text: string) => {
    const words = text.trim().split(/\s+/);

    if (words.length <= props.maxWords) {
      return [text, ''];
    }

    const firstString = words.slice(0, props.maxWords).join(' ');
    const secondString = words.slice(props.maxWords).join(' ');

    return [firstString, secondString];
  };

  const readFull = ref<HTMLElement>();

  const isReadFull = ref(false);

  const splittedText = ref(splitText(props.text));

  const isTruncate = computed(() => {
    return splittedText.value[1].length > 0 && !isReadFull.value;
  });
</script>

<template>
  <div class="text">
    <!-- Vue js issue https://github.com/vuejs/core/issues/10928 v-if="splittedText[1].length>0" @toggle="toggleDetails"-->
    <div v-if="isTruncate" class="class">
      <details>
        <summary ref="readFull">
          <span>
            {{ splittedText[0] }}...
          </span>
          <span class="toggle-button">Читать целиком</span>
        </summary>
        <article>
          <p>{{ splittedText[0] }}&nbsp;{{ splittedText[1] }} <span class="toggle-button" @click="readFull?.click"> Скрыть</span></p>
        </article>
      </details>
    </div>
    <div v-else>
      {{ text }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.truncate-text {
  /* & + :not(details[open]) {
    &::after {
      content: ' …';
    }
  } */
  &::after {
    content: ' …'
  }
}
details {
  display: inline;
  &[open] summary {
    display: none;
  }
}
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}
.toggle-button {
  cursor: pointer;
  color: #2D7FF9;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
</style>
