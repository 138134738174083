<script setup lang="ts">
  import NameInput from '~/UI/Inputs/NameInput.vue';
  import PhoneInput from '~/UI/Inputs/PhoneInput.vue';

  const reviewsFormStore = useReviewsFormStore();
  const { reviewText, getReviewTextLength, errorText } = storeToRefs(reviewsFormStore);
  watch(reviewText, () => {
    reviewsFormStore.clearErrorText();
  });
</script>

<template>
  <div class="inputs-container">
    <div class="review-input-container">
      <textarea
        v-model="reviewText"
        maxlength="5000"
        placeholder="Расскажите с чем вы пришли к врачу. Какое лечение было назначено, сколько продолжалось лечение. Расскажите о результатах лечения"
        class="review-input"
      ></textarea>
      <div class="max-length-indicator">
        {{ getReviewTextLength }} / 5000
      </div>
    </div>
    <div>
      <span v-if="errorText" style="margin-left: 0" class="input-error-message">{{ errorText }}</span>
    </div>
    <NameInput />
    <PhoneInput />
    <!-- <input
      v-model="name"
      type="text"
      placeholder="Имя"
      class="input-container"
    >
    <input
      v-model="phone"
      type="tel"
      placeholder="Введите номер телефона"
      class="input-container"
    > -->
  </div>
</template>

<style scoped lang="scss">
.inputs-container {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  margin-top: 32px;
}
.review-input-container {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  height: 160px;
  padding: 12px;
  border-radius: 15px;
  background-color: #F2F3F6;
}
.review-input {
  /* @include scroll; */
  display: flex;
  flex-grow: 1;
  border-width: 0;
  background-color: #F2F3F6;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  &::placeholder {
    color: #878FA2;
    font-feature-settings: 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
}
textarea {
  resize: none
}
.max-length-indicator {
  color: #878FA2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  text-align: end;
}
.input-container {
  height: 56px;
  padding: 16px 12px 12px 12px;
  border-radius: 15px;
  background-color: #F2F3F6;
  &::placeholder {
    color: #878FA2;
    font-feature-settings: 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.08px;
  }
}
</style>
