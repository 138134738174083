<script setup lang="ts">
  const reviewsFormStore = useReviewsFormStore();
  const { reviewStatus } = storeToRefs(reviewsFormStore);
</script>

<template>
  <Teleport to="body">
    <div class="modal">
      <ReviewsModalForm v-if="reviewStatus === 'not-sended'" />
      <ReviewsModalNegativeReview v-else-if="reviewStatus === 'negative'" />
      <ReviewsModalPositiveReview v-else-if="reviewStatus === 'positive'" />
    </div>
    <div class="overlay" @click.stop="reviewsFormStore.closeModal"></div>
  </Teleport>
</template>

<style scoped lang="scss">
.modal {
  z-index: 102;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 584px;
  background-color: white;
  padding: 40px;
  border-radius: 30px;
  overflow: auto;
  max-height: 100vh;
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    padding: 20px;
  }
}
.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
