<script setup lang="ts">
  const props = defineProps<{answer:string, answerDate:string}>();

  const answerDate = useDateFormat(props.answerDate, 'DD.MM.YYYY');
</script>

<template>
  <div v-if="answer" class="answer-with-line">
    <div class="line"></div>
    <div class="answer-container">
      <div class="answer-header">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <rect width="40" height="40" fill="white"></rect>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M31.4775 32.1173C29.6638 33.5349 27.5646 34.5431 25.3243 35.0725C23.0839 35.6018 20.7555 35.6399 18.499 35.184C15.1189 34.6511 11.9751 33.1205 9.47071 30.7887C6.96636 28.4568 5.2157 25.43 4.44327 22.0965C3.67084 18.7629 3.91187 15.2746 5.13543 12.0789C6.359 8.8833 8.50931 6.12602 11.3106 4.16077C9.52059 5.61724 8.08004 7.45663 7.09499 9.5435C6.10993 11.6304 5.60545 13.9116 5.61868 16.2192C5.61868 23.4445 11.3351 25.3827 13.8376 25.3827C17.5177 25.3827 23.7493 21.9847 28.1655 17.6667C26.3591 19.4668 24.4014 21.1085 22.3141 22.5736C12.4882 29.4063 23.0869 37.7601 31.4775 32.1173Z" fill="url(#paint0_linear_20331_3936)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3146 30.55L32.6737 31.2131C32.2888 31.5543 31.8787 31.8659 31.447 32.1454C23.0809 37.7883 12.4822 29.4344 22.3081 22.5649C24.3954 21.0998 26.353 19.4582 28.1594 17.6581C28.9506 16.8917 29.6884 16.072 30.3675 15.2047L30.8336 14.6159C31.308 13.9854 31.7423 13.3258 32.1339 12.6409C32.3367 12.2718 32.5169 11.8908 32.6737 11.5C32.7841 11.2179 32.87 10.9358 32.9558 10.6782C33.3871 8.99665 33.2885 7.223 32.6737 5.59961C32.8737 5.84969 33.058 6.11189 33.2257 6.3847C33.287 6.48283 33.422 6.74044 33.4342 6.77724C35.9858 12.3342 27.4847 20.1606 25.6815 21.927C24.7942 22.82 24.0346 23.8314 23.4244 24.9324C22.6025 26.6498 22.2835 28.5144 23.2158 29.7534C23.9396 30.7102 27.3717 34.0599 33.3146 30.55Z" fill="url(#paint1_linear_20331_3936)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M33.2396 8.53867C33.2514 8.75932 33.2514 8.98044 33.2396 9.20109C33.2482 9.21601 33.2527 9.23293 33.2527 9.25016C33.2527 9.26739 33.2482 9.28431 33.2396 9.29923C33.2339 9.50146 33.2093 9.7027 33.166 9.90031C33.166 10.1334 33.0678 10.3787 33.0065 10.6241C32.9452 10.8694 32.8348 11.1638 32.7244 11.446C32.5676 11.8368 32.3873 12.2177 32.1846 12.5868C31.793 13.2717 31.3587 13.9313 30.8843 14.5618L30.4182 15.1506C29.739 16.0179 28.9971 16.8633 28.2059 17.6297C23.7162 22.009 17.5869 25.3813 13.8332 25.3813C11.3798 25.3813 5.61424 23.772 5.61426 16.2178C5.61426 13.9101 6.02172 11.8065 7.09056 9.54208C8.07562 7.45521 9.51617 5.61583 11.3062 4.15935L11.5147 3.99988C10.0304 5.48419 4.36302 12.6972 10.5088 18.8307C11.494 19.7991 12.7158 20.4922 14.0525 20.8409C15.3892 21.1896 16.7939 21.1817 18.1266 20.818C25.1801 19.3214 32.847 11.1515 32.847 6.88263C32.841 6.4263 32.8294 6.01311 32.6731 5.58436C32.6731 5.58436 32.7314 5.70409 32.7927 5.86356C32.8541 6.02303 33.1496 6.55949 33.1118 6.74103C33.2031 7.14444 33.2166 7.48474 33.2396 7.89771C33.3009 8.08171 33.2396 8.31787 33.2396 8.53867Z" fill="url(#paint2_linear_20331_3936)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0561 13.6165C18.77 14.6844 18.2007 15.6552 17.4082 16.4261C16.6157 17.197 15.6295 17.7394 14.5541 17.9958C13.2219 17.5806 12.1023 16.6656 11.4302 15.4428C10.758 14.22 10.5856 12.7844 10.9489 11.4372C11.3123 10.09 12.1833 8.93583 13.3792 8.2169C14.5751 7.49796 16.003 7.2701 17.3632 7.58111C18.1621 8.3419 18.741 9.30405 19.0389 10.3662C19.3368 11.4284 19.3428 12.5512 19.0561 13.6165Z" fill="url(#paint3_linear_20331_3936)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1656 14.2067C20.7895 15.5849 19.8824 16.7578 18.643 17.4683C17.4036 18.1789 15.9331 18.369 14.5537 17.9972C15.6289 17.7422 16.5334 17.2015 17.3266 16.4321C18.1199 15.6628 18.6905 14.6935 18.9783 13.6265C19.2661 12.5596 19.2603 11.4348 18.9615 10.3709C18.6627 9.30703 18.164 8.34363 17.3629 7.58252C18.7443 7.95872 19.9202 8.86718 20.633 10.1088C21.3458 11.3505 21.5373 12.8241 21.1656 14.2067Z" fill="url(#paint4_linear_20331_3936)"></path>
          <defs>
            <linearGradient
              id="paint0_linear_20331_3936"
              x1="13.5922"
              y1="33.6507"
              x2="23.6144"
              y2="9.20251"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_20331_3936"
              x1="23.7873"
              y1="32.33"
              x2="35.6634"
              y2="14.244"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_20331_3936"
              x1="15.2676"
              y1="24.1197"
              x2="20.294"
              y2="6.03352"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint3_linear_20331_3936"
              x1="13.7314"
              y1="17.3733"
              x2="17.3073"
              y2="9.35405"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint4_linear_20331_3936"
              x1="16.9267"
              y1="17.5578"
              x2="21.0466"
              y2="10.2076"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <div class="answer-info">
          <span class="answer-info-text">Ист Клиника</span>
          <span class="answer-info-date">{{ answerDate }}</span>
        </div>
      </div>
      <ReviewsTextReadFull :text="answer" :max-words="28" />
      <!-- TODO
      <div class="likes">
        <IconHeartRed v-if="isLiked" filled class="icon-heart" />
        <IconHeart v-else filled class="icon-heart" />
        <span
          v-if="likes>0"
          class="likes-count"
          :style="`color:${isLiked?'#FF3434':'#878FA2'}`"
        >
          {{ likes }}
        </span>
      </div>
      TODO -->
    </div>
  </div>
</template>

<style scoped lang="scss">
.answer-with-line {
  display: flex;
  gap: 12px;
}
.line {
  width: 22px;
  height: 32px;
  border-left: 2px solid  #E4E7EF;
  border-bottom: 2px solid #E4E7EF;
  border-bottom-left-radius: 8px;
}
.answer-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  width: 100%;
}
.answer-header {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
}
.answer-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.answer-info-text {
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.answer-info-date {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.likes {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
}
.icon-heart {
  font-size: 24px;
  margin: 0;
  cursor: pointer;
}
.likes-count {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
</style>
