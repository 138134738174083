<script setup lang="ts">

  const props = defineProps({
    items: {
      type: [Object, Array],
      required: true
    },
    header: String,
    small: {
      type: Boolean,
      default: false
    },
    works: {
      type: Boolean,
      default: false
    }
  });

  const disabledBack = ref(true);
  const disabledNext = ref(false);
  const scrollSize = ref(0);
  const containerSize = ref(0);
  const screens = ref(0);
  const currentScreen = ref(1);

  const scrollBlock = ref(null);
  // console.log(props.items);

  const images = computed(() => {
    const images = [];
    let i = 0;
    for (const item in props.items) {
      let image = '';
      if (props.items[item].image) {
        image = props.items[item].image;
      }
      if (props.items[item].source) {
        image = props.items[item].source;
      }
      if (props.items[item].url) {
        image = props.items[item].url;
      }
      // let image = props.items[item].image ? props.items[item].image : props.items[item].source;
      if (image) {
        image = image.includes('https://eastclinic.ru') ? image : 'https://eastclinic.ru' + image;
      }
      images[i] = { image: { img: image }, thumb: i };
      i++;
    }
    return images;
  });

  const itemsLength = computed(() => {
    return typeof props.items === 'object' ? Object.keys(props.items).length : 0;
  });

  const openModal = (key) => {
    emit('openModal', images.value, key);
  };
  // когда перейдем на общий контент этого не будет
  const getImage = (item) => {
    let image = '';
    if (item?.source) {
      image = item?.source;
    }
    if (item.image?.img) {
      image = item?.image?.img;
    }
    if (item?.url) {
      image = item?.url;
    }
    if (image) {
      image = image.includes('https://eastclinic.ru') ? image : 'https://eastclinic.ru' + image;
    }
    return image;
  };

  const getName = (item, key) => {
    return props.items[key + 1]?.name;
  };

  const getTitle = (item, key) => {
    return props.items[key + 1]?.title;
  };

  const getPortal = (item, key) => {
    return props.items[key + 1]?.portal;
  };

  const handleScroll = (e) => {
    // console.log(e);
  };

  const backClick = () => {
    if (disabledBack.value) { return; }
    currentScreen.value = currentScreen.value !== 1 ? currentScreen.value - 1 : currentScreen.value;
    disabledBack.value = currentScreen.value === 1;
    disabledNext.value = currentScreen.value === screens.value;
    scrollBlock.value?.children[0].scrollTo({
      left: containerSize.value * currentScreen.value - containerSize.value,
      behavior: 'smooth'
    });
  };

  const nextClick = () => {
    if (disabledNext.value) { return; }
    scrollBlock.value?.children[0].scrollTo({
      left: containerSize.value * currentScreen.value,
      behavior: 'smooth'
    });
    currentScreen.value = currentScreen.value < screens.value ? currentScreen.value + 1 : currentScreen.value;
    disabledNext.value = currentScreen.value === screens.value;
    disabledBack.value = currentScreen.value === 1;
  };

  onMounted(() => {
    setTimeout(() => {
      if (scrollBlock.value) {
        const items = scrollBlock.value.children[0].children;

        for (let i = 0; i < items.length - 1; i++) {
          const style = window.getComputedStyle(items[i]);
          scrollSize.value += items[i].scrollWidth + +style['margin-right'].replace('px', '');
        }
        containerSize.value = scrollBlock.value.offsetWidth;
        screens.value = Math.ceil(scrollSize.value / containerSize.value);
        scrollBlock.value.addEventListener('scroll', handleScroll);
      }
    }, 1000);
  });

  onBeforeUnmount(() => {
    if (scrollBlock.value) {
      scrollBlock.value.children[0].removeEventListener('scroll', handleScroll);
    }
  });
  const showGalleryModal = ref(false);
  const openGallery = () => {
    showGalleryModal.value = true;
  };
</script>

<template>
  <div
    v-if="itemsLength > 0"
    :class="{ small }"
    class="slide-gallery"
  >
    <ModalGalleryImagesGalleryModalWrapper
      v-model:visible="showGalleryModal"
      :items="images"
    />
    <hr v-if="header" class="single-doctor-hr">
    <div class="slide-gallery__header">
      <div v-if="header" class="slide-gallery__header__text text-bold">
        {{ header }}
      </div>
      <div v-if="screens > 1" class="slide-gallery__header__nav">
        <div class="slide-gallery__header__nav__counter">
          {{ currentScreen }}/{{ screens }}
        </div>
        <div class="slide-gallery__header__nav__buttons">
          <div
            :class="{ disabled: disabledBack }"
            class="slide-gallery__header__nav__buttons__back"
            @click="backClick"
          >
            <svg
              style="transform:rotate(180deg)"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_14535_6013)">
                <path
                  d="M10.9876 8.12285C11.4209 7.68952 12.1209 7.68952 12.5543 8.12285L17.6543 13.2229C18.0876 13.6562 18.0876 14.3562 17.6543 14.7895L12.5543 19.8895C12.1209 20.3228 11.4209 20.3228 10.9876 19.8895C10.5543 19.4562 10.5543 18.7562 10.9876 18.3228L15.2987 14.0006L10.9876 9.68952C10.5543 9.25618 10.5654 8.54507 10.9876 8.12285Z"
                  fill="#323232"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_14535_6013">
                  <rect
                    width="26.6667"
                    height="26.6667"
                    fill="white"
                    transform="matrix(-1 0 0 1 27.332 0.666992)"
                  ></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            :class="{ disabled: disabledNext }"
            class="slide-gallery__header__nav__buttons__next"
            @click="nextClick"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <g clip-path="url(#clip0_14535_6013)">
                <path
                  d="M10.9876 8.12285C11.4209 7.68952 12.1209 7.68952 12.5543 8.12285L17.6543 13.2229C18.0876 13.6562 18.0876 14.3562 17.6543 14.7895L12.5543 19.8895C12.1209 20.3228 11.4209 20.3228 10.9876 19.8895C10.5543 19.4562 10.5543 18.7562 10.9876 18.3228L15.2987 14.0006L10.9876 9.68952C10.5543 9.25618 10.5654 8.54507 10.9876 8.12285Z"
                  fill="#323232"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_14535_6013">
                  <rect width="26.6667" height="26.6667" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="slide-gallery__content">
      <div ref="scrollBlock" class="scroll-container">
        <div class="d-flex scrolling-block">
          <a
            v-for="(item, key) in images"
            :key="key"
            href="#"
            :class="{ works }"
            class="slide-gallery__item here1"
            @click.prevent="openGallery()"
          >
            <div class="slide-gallery__item__image">
              <img :src="getImage(item)">
            </div>
            <div
              v-if="getName(item, key)"
              class="slide-gallery-item-header"
            >
              {{ getName(item, key) }}
            </div>
            <div v-if="getTitle(item, key)" class="slide-gallery__item__header">
              {{ getTitle(item, key) }}
            </div>
            <div
              v-if="getPortal(item, key)"
              class="slide-gallery__item__desc annotation"
            >
              {{ getPortal(item, key) }}
            </div>
            <div v-if="works" class="slide-gallery__item__link text-semibold">
              <span>Читать историю</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_14535_5049)">
                  <path
                    d="M7.74167 5.59148C7.41667 5.91648 7.41667 6.44148 7.74167 6.76648L10.975 9.99981L7.74167 13.2331C7.41667 13.5581 7.41667 14.0831 7.74167 14.4081C8.06667 14.7331 8.59167 14.7331 8.91667 14.4081L12.7417 10.5831C13.0667 10.2581 13.0667 9.73315 12.7417 9.40815L8.91667 5.58315C8.60001 5.26648 8.06667 5.26648 7.74167 5.59148Z"
                    fill="#2D7FF9"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_14535_5049">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .slide-gallery-item-header {
    color: #262633;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .scroll-container {
      display: flex;
      overflow-x: hidden;
      overflow-y: visible;
  }
  .scroll-container .scrolling-block {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(192, 191, 191, .27843) #fff;
  }
  .slide-gallery__content .scrolling-block {
      overflow-x: scroll;
  }
  .slide-gallery {
    &__item {
      width: 180px;
      flex-shrink: 0;

      &.works {
        & .slide-gallery__item__image {
          height: 247px;
          max-height: 247px;
        }
      }
      &:hover {
        text-decoration: none !important;
      }
      &:not(:last-child) {
        margin-right: 16px;
      }
      &__image {
        max-height: 127px;
        margin-bottom: 8px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 4px 24px 0 rgba(99, 105, 119, 0.25);
        & img {
          width: 100%;
        }
      }
      &__link {
        color: #2D7FF9;
        margin-top: 4px;
        & svg {
          position: relative;
          top: 5px;
        }
      }
    }
    &__header {
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        font-size: 20px;
      }
      &__text {
        font-size: 24px;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      &__nav {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          display: none;
        }
        &__buttons {
          display: flex;

          &__back {
            margin: 0 16px;
          }
          &__back, &__next {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            border: 1px solid #E5E5E7;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            box-shadow: 0 2px 8px 0 rgba(33, 35, 38, 0.15);
            &.disabled {
              cursor: inherit;
              box-shadow: none;
              & svg path {
                fill: #E5E5E7;
              }
            }
          }
        }
      }
    }
    &__content {
      margin-bottom: 24px;
      & p {
        margin: 0;
      }
      & .scrolling-block {
        overflow-x: scroll;
      }
    }
    &__footer {

    }
    &.small {
      & .slide-gallery__item {
        height: 80px;
        width: 110px;
        border-radius: 10px;
        overflow: hidden;
        &:not(:last-child) {
          margin-right: 8px;
        }
        &__image {
          height: 80px;
          display: flex;
          align-items: center;

          & img {
            width: 100%;
          }
        }
      }
    }
  }
</style>
