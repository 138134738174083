<script setup lang="ts">
  import Breadcrumbs from '~/UI/Breadcrumbs.vue';
  import { PageInfoService } from '~/EastclinicVueApi';

  const { pageInfo } = storeToRefs(usePageInfoStore());
  const { currentClinic } = storeToRefs(useClinicsStore());

  const isMobile = useMediaQuery('(max-width: 1023px)');

  const isClinicInfo = computed(() => {
    return pageInfo.value?.id === 6 && !!currentClinic.value;
  });

  const { data: catalogData } = await useLazyAsyncData(
    `catalogData-${pageInfo.value?.id}`,
    () => PageInfoService.getCatalogData(pageInfo.value) // use service, because save catalog data inside this layout
  );

  const { showModal } = storeToRefs(useSearchPanelStore());

  const searchEl = ref();

  const { top: searchPosition } = useElementBounding(searchEl);

  const isStickySearchPanel = useMediaQuery('(min-width: 1023px)');

  const stickySearchPanelStyles = computed(() => {
    if (isStickySearchPanel.value) {
      return {
        position: 'sticky',
        top: '0px'
      };
    }
    return {
      position: 'static',
      top: 'initial'
    };
  });

  const isSearchPanelOnHeader = computed(() => {
    // 23 is padding-top value in px
    if (searchPosition.value < 24) {
      return '23px';
    }
    return '8px';
  });
  const isSearchPanelOnDefaultPosition = computed(() => {
    if (showModal.value === false && searchPosition.value >= 88) {
      return 99; // z-index: 99 before-header
    }
    return 101; // z-index: 101 after-header
  });

  const bottomEl = ref();

  const { activeBottom } = storeToRefs(useNavigationStore());

  useIntersectionObserver(
    bottomEl,
    ([{ isIntersecting }]) => {
      activeBottom.value = isIntersecting;
    },
    {
      rootMargin: '-120px 0px 0px 0px'
    }
  );
</script>

<template>
  <div>
    <Header />
    <div
      v-if="(pageInfo?.type !== 'doctor' && pageInfo?.type !== 'cabinet') || useContext() === 'operator'"
      class="layout-search-panel white-container"
    >
      <SearchPanel ref="searchEl" />
      <DoctorContentNavigation v-if="searchPosition < 24 && isMobile === false" />
    </div>
    <div v-if="pageInfo?.type !== 'doctor' && pageInfo?.type !== 'cabinet'" class="white-container">
      <div class="main-container">
        <AwardsCarouselLayout />
        <Breadcrumbs v-if="pageInfo?.type!=='dismissDoctor'" :items="pageInfo?.breadcrumbs ?? []" />
        <DoctorContentClinicInfoFull v-if="isClinicInfo" />
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
    <div ref="bottomEl" class="doctors-bottom">
      <Catalog v-if="pageInfo?.type !== 'doctor' && pageInfo?.type !== 'cabinet' && catalogData && pageInfo?.id !== 7806" :title="translateCatalogData(Object.keys(catalogData.data)[0])" :data="Object.values(catalogData.data)[0]" />
      <Footer />
    </div>
  </div>
</template>

<style scoped lang="scss">
.layout-search-panel {
  padding-top: v-bind(isSearchPanelOnHeader);
  margin-bottom: 24px;
  z-index: v-bind(isSearchPanelOnDefaultPosition);
  position: v-bind('stickySearchPanelStyles.position');
  top: v-bind('stickySearchPanelStyles.top');
  @media (max-width: 1023px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
.mb-6 {
  padding-bottom: 24px;
}
.doctors-bottom {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-top: 40px;
  padding-top: 12px;
  background-color: white;
  @media (max-width: 1023px) {
    gap: 80px;
  }
}
</style>
