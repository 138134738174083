<script setup lang="ts">
  import { CommentsService, type ChevronInterface } from '~/EastclinicVueApi';

  interface Badge {
    text: string
    icon: string
  }

  interface Props {
    speciality: string
    fullName: string
    url: string
    chevrons: ChevronInterface[]
    doctorId: number
  }

  const props = defineProps<Props>();

  const commentsService = new CommentsService();

  const doctorsStore = useDoctorsStore();

  const { doctorsCommentsMap } = storeToRefs(doctorsStore);

  const convertTitleToIcon = (title: string) => {
    switch (title) {
      case 'Ведущий врач': return 'TopSpecialist';
      case 'Ведущий специалист': return 'TopSpecialist';
      case 'Кандидат медицинских наук': return 'Candidate';
      case 'Врач высшей категории': return 'HigherCategory';
      case 'Главный врач': return 'HeadDoctor';
      case 'Врач-эксперт': return 'Expert';
      case 'Врач первой категории': return 'FirstCategory';
      case 'Врач второй категории': return 'SecondCategory';
      case 'Доктор медицинских наук': return 'Candidate';
      case 'Научный руководитель': return 'Instructor';
      default: return null;
    }
  };

  const badges = computed(() => {
    const res: Badge[] = [];
    for (const chevron of props.chevrons) {
      if (res.length === 2) {
        return res;
      }
      const icon = convertTitleToIcon(chevron.title);
      if (icon === null) {
        continue;
      }
      const text = chevron.title === 'Кандидат медицинских наук' ? 'КМН' : chevron.title;
      res.push({
        text,
        icon
      });
    }
    return res;
  });

  const isPrimary = ref(false);

  const togglePrimary = async () => {
    let commentBody = doctorsStore.tryParseComment(props.doctorId);
    if (!commentBody) {
      commentBody = {
        doctor: {
          1: { text: '' },
          2: { text: '' }
        },
        favorite: false,
        service: {}
      };
    }
    commentBody.favorite = !isPrimary.value;

    const commentRequest = {
      body: JSON.stringify(commentBody),
      commentable_id: [props.doctorId],
      commentable_type: 'doctor' as const
    };

    try {
      const res = await commentsService.createComment(commentRequest);

      if (!res.ok) {
        throw new Error('Ошибка авторизации');
      }
      doctorsCommentsMap.value.set(res.data.commentable_id, res.data);
      isPrimary.value = !isPrimary.value;
    } catch (err) {}
  };

  onMounted(() => {
    if (useContext() === 'operator') {
      setTimeout(() => {
        const comment = doctorsStore.tryParseComment(props.doctorId);
        if (comment) {
          isPrimary.value = comment.favorite;
        }
      }, 600);
    }
  });
</script>

<template>
  <div class="info-container">
    <NuxtLink :to="url" class="doctor-speciality">
      {{ speciality }}
    </NuxtLink>
    <div class="fullname-container">
      <NuxtLink :to="url" class="doctor-full-name">
        {{ fullName }}
      </NuxtLink>
      <svg
        v-if="useContext() === 'operator'"
        style="cursor:pointer"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        :fill="isPrimary ? '#F79009' : '#E0E2E8'"
        @click="togglePrimary"
      >
        <path d="M16.743 11.9379L16.9038 10.3595C16.9896 9.51733 17.0463 8.96123 17.0018 8.61085L17.0174 8.61095C17.7441 8.61095 18.3332 7.98912 18.3332 7.22206C18.3332 6.455 17.7441 5.83317 17.0174 5.83317C16.2907 5.83317 15.7016 6.455 15.7016 7.22206C15.7016 7.56897 15.8221 7.88617 16.0213 8.12958C15.7353 8.31592 15.3614 8.70909 14.7985 9.30089L14.7985 9.3009L14.7985 9.30091C14.3649 9.75682 14.1481 9.98478 13.9062 10.0201C13.7722 10.0396 13.6357 10.0195 13.5119 9.96202C13.2886 9.85821 13.1397 9.5764 12.8419 9.01276L11.2722 6.04187C11.0884 5.69417 10.9347 5.40314 10.796 5.16896C11.3648 4.86268 11.7542 4.23853 11.7542 3.51836C11.7542 2.49561 10.9688 1.6665 9.99984 1.6665C9.03092 1.6665 8.24545 2.49561 8.24545 3.51836C8.24545 4.23853 8.63491 4.86268 9.20364 5.16896C9.06499 5.40316 8.91125 5.69413 8.72751 6.04187L7.15778 9.01276L7.15778 9.01277C6.85998 9.57639 6.71107 9.85821 6.48774 9.96202C6.36399 10.0195 6.22745 10.0396 6.09343 10.0201C5.85157 9.98478 5.63476 9.75682 5.20115 9.3009C4.63832 8.70911 4.26436 8.31592 3.97837 8.12958C4.17759 7.88617 4.29808 7.56897 4.29808 7.22206C4.29808 6.455 3.70898 5.83317 2.98229 5.83317C2.2556 5.83317 1.6665 6.455 1.6665 7.22206C1.6665 7.98912 2.2556 8.61095 2.98229 8.61095L2.99783 8.61085C2.95336 8.96123 3.01003 9.51733 3.09584 10.3595L3.25668 11.9379C3.34596 12.814 3.4202 13.6477 3.51113 14.398H16.4885C16.5795 13.6477 16.6537 12.814 16.743 11.9379Z"></path>
        <path d="M9.04551 18.3332H10.9542C13.4418 18.3332 14.6857 18.3332 15.5156 17.5491C15.8778 17.2068 16.1072 16.5898 16.2727 15.7869H3.72699C3.89251 16.5898 4.12188 17.2068 4.48411 17.5491C5.31402 18.3332 6.55785 18.3332 9.04551 18.3332Z"></path>
      </svg>
    </div>
    <div v-if="badges.length > 0" class="badges-container">
      <div v-for="badge in badges" :key="badge.icon" class="badge">
        <component :is="`IconBadge${badge.icon}`" :filled="true" class="icon-badge" />
        <span class="badge-text">{{ badge.text }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.info-container {
  grid-area: info;
}
.doctor-speciality {
  display: block;
  text-decoration: none;
  color: #666E81 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.fullname-container {
  display: inline-flex;
  align-items: start;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 12px;
}
.doctor-full-name {
  display: block;
  text-decoration: none;
  color: #16162D;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  @media (max-width: 585px) {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: 0.024px;
  }
}
.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 585px) {
    justify-content: start;
    width: calc(100vw - 230px);
  }
}
.badges-container {
  display: flex;
  gap: 12px;
  @media (max-width: 585px) {
    flex-direction: column;
    align-items: start;
  }
  @media (min-width: 768px) {
    padding-bottom: 10px;
  }
}
.icon-badge {
  font-size: 16px;
  min-width: 16px;
  margin: 0;
}
.badge-text {
  margin-left: 4px;
  color: #666E81;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  @media (max-width: 585px) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
