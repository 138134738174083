<script setup lang="ts">
  interface Props {
    title: string
  }

  defineProps<Props>();

  const hide = ref<HTMLElement>();

  const onClick = () => {
    if (hide.value) {
      hide.value.click();
      hide.value?.scrollIntoView({ behavior: 'smooth' });
    }
  };
</script>

<template>
  <details class="accordion-container">
    <summary ref="hide" class="accordion">
      <span class="accordion-text">{{ title }}</span>
      <IconChevronDown class="chevron" />
    </summary>
    <div class="accordion-content">
      <slot></slot>
    </div>
    <button
      style="margin-top: 12px;"
      class="button gray-color medium full-width-mobile text-semibold"
      @click="onClick"
    >
      Свернуть
    </button>
  </details>
</template>

<style scoped lang="scss">
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}
.accordion-container {
  padding: 32px 0px;
  width: 100%;
  border-bottom: 1px solid #E5E5E7;
  &:first-of-type {
    border-top: 1px solid #E5E5E7;
  }
}
.accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  scroll-margin-top: 100px;
}
.accordion-text {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 26px;
  }
}
.chevron {
  font-size: 24px;
  margin: 0px;
}
details[open] .chevron {
  transform: rotate(180deg);
}
.accordion-content {
  margin-top: 24px;
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
</style>
