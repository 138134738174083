import type { IDoctor } from '../../EastclinicVueApi';
export default function (doctor:IDoctor, shortlist = false, joinString:string = ' · ') : string {
  if (doctor.seoServiceData?.[0]?.specialityTitle) {
    return doctor.seoServiceData?.[0]?.specialityTitle;
  } else if (doctor.main_specials) {
    if (shortlist) {
      return doctor.main_specials.slice(0, 3).join(joinString);
    }
    return doctor.main_specials?.join(joinString);
  }
  return '';
}
