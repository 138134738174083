<script setup lang="ts">
  interface Props {
    doctorId : number
  }

  const props = defineProps<Props>();

  const devScheduleStore = useDevScheduleStore();
  const { clinicForNearestSlot, schedulesByDoctorId } = storeToRefs(devScheduleStore);

  const showBanner = computed(() => {
    if (clinicForNearestSlot.value === null) {
      return false;
    }
    const days = schedulesByDoctorId.value.get(props.doctorId)?.find((s) => s.clinicId === clinicForNearestSlot.value?.id)?.days;
    if (!days || days.length < 2) {
      return false;
    }
    let slotsCount = 0;
    for (let i = 0; i < 2; i++) {
      slotsCount += days[i].slots.length;
    }
    return slotsCount > 0 && slotsCount < 3;
  });

  onUnmounted(() => {
    devScheduleStore.deleteNearestSlot();
  });
</script>

<template>
  <div v-if="showBanner" class="banner-container">
    <img src="~/assets/img/doctor-clock.webp" class="clock-img">
    <div class="text-block">
      <span class="text-bold">Свободное время у врача – редкость</span>
      <span class="text-info">Обычно записаться к этому доктору сложно, но сейчас у вас появилась возможность попасть на приём <span style="text-wrap: nowrap;">в ближайшие дни.</span></span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.banner-container {
  display: flex;
  align-items: start;
  gap: 16px;
  padding: 20px;
  border-radius: 30px;
  border: 1px solid #E4E7EF;
  background-color: white !important;
  @media (max-width: 1023px) {
    padding: 12px;
  }
  @media (min-width: 1023px) {
    max-width: 377px;
  }
}
.clock-img {
  width: 44px;
  @media (max-width: 1023px) {
    width: 32px;
  }
}
.text-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1023px) {
    gap: 4px;
  }
}
.text-bold {
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (min-width: 1166px) {
    text-wrap: nowrap;
  }
}
.text-info {
  color: #262633;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
</style>
