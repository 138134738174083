<script setup lang="ts">
  import { ReviewsService, usePatientStore, type INewReview } from '~/EastclinicVueApi';
  import Checkbox from '~/UI/Inputs/Checkbox.vue';
  import useCalltouch from '~/composables/useCalltouch';
  import EcButton from '~/UI/Buttons/EcButton.vue';
  import YandexCaptchaWrapper from '~/UI/Yandex/CaptchaWrapper.vue';

  const reviewsFormStore = useReviewsFormStore();

  const statePatient = usePatientStore();

  const reviewsService = new ReviewsService();

  // todo нужно перенести в patient
  const bookingStore = useBookingStore();

  const captchaWrapper = ref<HTMLElement | null>(null);
  const error = ref<string>('');

  const submit = () => {
    bookingStore.loading = true;
    // reviewsFormStore.validate() - not need
    if (!bookingStore.validate()) {
      setTimeout(() => {
        bookingStore.loading = false;
      }, 100);
      return;
    }
    if (captchaWrapper.value) {
      captchaWrapper.value?.captchaSubmit();
    } else {
      setTimeout(() => {
        error.value = 'Что-то пошло не так. Попробуйте обновить страницу и отправить форму ещё раз.';
        bookingStore.loading = false;
      }, 300);
    }
  };

  const sendReview = async () => {
    const review: INewReview = {
      fio: statePatient.patientData.fio,
      phone: statePatient.patientData.phone,
      reviewable_type: 'doctor',
      reviewable_id: reviewsFormStore.doctorId,
      rating: reviewsFormStore.selectedRating * 100 / 5,
      text: reviewsFormStore.reviewText
    };
    const res = await reviewsService.saveNewReview(review);
    if (!res.ok) {
      bookingStore.loading = false;
      return;
    }
    bookingStore.loading = false;

    const { onLoaded } = useYandexMetrika();

    onLoaded((ym) => {
      ym.reachGoal('review_send');
    });

    useCalltouch().forPatient(statePatient.patientData).booking();
    if (reviewsFormStore.selectedRating < 3) {
      reviewsFormStore.reviewStatus = 'negative';
    } else {
      reviewsFormStore.reviewStatus = 'positive';
    }
  };
</script>

<template>
  <div class="reviews-form-container">
    <div class="top">
      <div class="title-container">
        <span class="title">Как вам приём у врача?</span>
        <div class="close-container" @click="reviewsFormStore.closeModal">
          <IconClose class="icon-close" />
        </div>
      </div>
      <div class="stars">
        <IconReviewStar
          v-for="star in 5"
          :key="star"
          :style="`color: ${ star <= reviewsFormStore.selectedRating ? '#FDB022':'#E8EAF0'}`"
          class="star"
          @click="reviewsFormStore.selectedRating = star"
        />
      </div>
      <ReviewsModalInputs />
    </div>
    <Checkbox />
    <ClientOnly>
      <YandexCaptchaWrapper
        ref="captchaWrapper"
        :callback="sendReview"
      />
    </ClientOnly>
    <div v-if="error" class="input-error-message">
      {{ error }}
    </div>
    <div class="bottom-container">
      <!-- <button type="button" class="send-review-button" @click="sendReview">
        Оставить отзыв
      </button> -->
      <EcButton
        :loading="bookingStore.loading"
        :disable="bookingStore.loading"
        class="primary full-width send-review-button"
        @click="submit"
      >
        <span>Оставить отзыв</span>
      </EcButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.reviews-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  @media (max-width: 680px) {
    max-height: 80vh;
  }
}
.top {
  display: flex;
  flex-direction: column;
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 11px;
}
.title {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.close-container {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #F2F3F6;
  cursor: pointer;
}
.icon-close {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.stars {
  cursor: pointer;
}
.star {
  font-size: 40px;
  margin: 0;
}
.bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom-text {
  width: 308px;
  height: 56px;
  color: #878FA2;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.send-review-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 56px;
  padding: 15px 16px;
  border-radius: 15px;
  background: #2D7FF9;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
</style>
