<script setup lang="ts">
  import type { IReviewsRequest, TPlatform } from '~/EastclinicVueApi';
  import { ReviewsService } from '~/EastclinicVueApi';

  interface Props {
    doctorId?: number
  }

  const props = withDefaults(defineProps<Props>(), {
    doctorId: 0
  });

  const reviewsService = new ReviewsService();
  const reviewsFormStore = useReviewsFormStore();
  const { doctorReviewsCount } = storeToRefs(useDoctorsStore());

  const showMore = ref(false);
  const page = ref(1);
  const currentFilterType = ref<string|null>(null);
  const currentFilterPlatform = ref<TPlatform|null>(null);
  const hideEightReviews = ref(true);

  const fetchReviews = async () => {
    if (!showMore.value) {
      page.value = 1;
    }
    const request: IReviewsRequest = {
      page: page.value,
      reviewable_type: '*',
      reviewable_id: props.doctorId,
      sortBy: currentFilterType.value,
      platform: currentFilterPlatform.value
    };
    const result = await reviewsService.fetchReviews(request);

    doctorReviewsCount.value = result.totalCount;

    if (!import.meta.client) {
      return result;
    }
    if (showMore.value && data.value) {
      showMore.value = false;
      result.items.unshift(...data.value.items);
    }
    return result;
  };

  const { data } = await useLazyAsyncData(
    `reviews-${props.doctorId}-${page.value}-${currentFilterType.value}-${currentFilterPlatform.value}`,
    fetchReviews,
    {
      watch: [page, currentFilterType, currentFilterPlatform]
    }
  );

  const showButton = computed(() => {
    return page.value !== data.value?.total_pages || (isShowTwoReviews.value && (data.value?.count ?? 0) > 2);
  });

  const isShowTwoReviews = computed(() => {
    return props.doctorId !== 0 && hideEightReviews.value;
  });

  const totalReviewsRating = computed(() => {
    const rating = data.value?.totalRating ?? 0;
    const maxOriginal = 100;
    const maxNew = 5;
    return (rating / maxOriginal) * maxNew;
  });

  const remainingPages = computed(() => {
    const totalCount = data.value?.count ?? 0;
    if (isShowTwoReviews.value) {
      return totalCount - 2 < 10 ? totalCount - 2 : 8;
    }
    const remaining = totalCount - page.value * 10;
    return remaining < 10 ? remaining : 10;
  });

  const getReviewsCase = computed(() => {
    const count = data.value?.totalCount ?? 0;
    const titles = ['отзыв', 'отзыва', 'отзывов'];
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[(count % 100 > 4 && count % 100 < 20) ? 2 : cases[(count % 10 < 5) ? count % 10 : 5]];
  });

  const getPlatformCountMap = computed(() => {
    const map = new Map<string, number>();
    const platforms = data.value?.platforms;

    map.set('AllPlatforms', data.value?.totalCount ?? 0);

    if (!platforms) {
      return map;
    }
    for (const platform of platforms) {
      if (platform.count && platform.source) {
        map.set(platform.source, platform.count);
      }
    }
    return map;
  });

  const clickOnShowMore = () => {
    if (isShowTwoReviews.value) {
      hideEightReviews.value = false;
      return;
    }
    showMore.value = true;
    page.value += 1;
  };

  watch(() => props.doctorId, (newDoctorId) => {
    reviewsFormStore.doctorId = newDoctorId;
  }, {
    immediate: true
  });

  onUnmounted(() => {
    doctorReviewsCount.value = 0;
  });
</script>

<template>
  <div id="reviews" class="bottom-container">
    <LazyReviewsNoReviews v-if="doctorId !== 0 && data?.count === 0" />
    <div v-else-if="data" class="reviews-container">
      <!-- <div
        itemProp="aggregateRating"
        itemType="https://schema.org/AggregateRating"
        <meta :content="doctor.fullname" itemProp="itemReviewed">
        <meta :content="doctor.comments + ''" itemProp="reviewCount">
        <meta :content="doctor.rating + ''" itemProp="ratingValue">
        <meta content="1" itemProp="worstRating">
        <meta content="5" itemProp="bestRating">
      </div> -->
      <!-- TODO
      <div class="last-media-container">
        <div v-for="i in 9" :key="i" class="last-media-item"></div>
      </div>
      <div class="reviews-search">
        <IconMagnifer filled class="magnifer" />
        <input
          type="text"
          placeholder="Поиск отзывов по врачам, услугам, клиникам"
          class="reviews-input"
        >
      </div>
      TODO -->
      <div v-if="doctorId !== 0" class="doctor-send-review-container">
        <div class="doctor-send-review">
          <IconDoctorLike filled class="icon-doctor-like" />
          <div class="doctor-send-review-text-container">
            <span class="doctor-recommendation-text">100% пациентов рекомендуют врача</span>
            <span class="doctor-reviews-count">{{ data.totalCount }} {{ getReviewsCase }}</span>
          </div>
        </div>
        <button type="button" class="total-reviews-button" @click="reviewsFormStore.openModal">
          Оставить отзыв
        </button>
      </div>
      <LazyReviewsPrompt />
      <div class="filters-container">
        <ReviewsFilterType v-model="currentFilterType" />
        <ReviewsFilterPlatform v-model="currentFilterPlatform" :platform-count-map="getPlatformCountMap" />
      </div>
      <div class="reviews">
        <ReviewsItem
          v-for="(review, index) in data.items"
          v-show="(isShowTwoReviews && (index === 0 || index === 1)) || !isShowTwoReviews"
          :key="review.id"
          v-bind="review"
        />
        <button v-if="showButton" type="button" class="show-more" @click="clickOnShowMore">
          Показать ещё {{ remainingPages }}
        </button>
      </div>
    </div>
    <div v-if="doctorId === 0" class="total-reviews-container">
      <div class="total-reviews">
        <span class="total-reviews-rating">{{ totalReviewsRating.toFixed(1) }}</span>
        <div class="stars">
          <IconReviewStar
            v-for="star in 5"
            :key="star"
            :style="`color: ${ star <= Number(totalReviewsRating.toFixed(0)) ? '#FDB022':'#E8EAF0'}`"
            class="star"
          />
        </div>
        <span class="total-reviews-number">{{ data?.totalCount }} {{ getReviewsCase }}</span>
      </div>
      <button type="button" class="total-reviews-button" @click="reviewsFormStore.openModal">
        Написать отзыв
      </button>
    </div>
    <ReviewsModal v-if="reviewsFormStore.showModal" />
  </div>
</template>

<style scoped lang="scss">
.bottom-container {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 100px;
  scroll-margin-top: 150px;
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    gap: 24px;
    scroll-margin-top: 120px;
  }
}
.total-reviews-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 1023px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
}
.total-reviews {
  display: flex;
  align-items: center;
  gap: 8px;
}
.total-reviews-rating {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.star {
  font-size: 20px;
  margin: 4px 0;
}
.total-reviews-number {
  color: #666E81;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.total-reviews-button {
  width: 281px;
  height: 48px;
  padding: 14px 16px;
  border-radius: 15px;
  background-color: #F2F3F6;
  text-align: center;
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 767px) {
    width: 100%;
  }
}

.reviews-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 775px;
  min-width: 0px;
  flex-shrink: 1;
  flex-grow: 1;
  @media (max-width: 1023px) {
    max-width: 100%;
  }
}
.last-media-container {
  display: flex;
  gap: 8px;
  overflow: hidden;
}
.last-media-item {
  width: 80px;
  height: 80px;
  border-radius: 15px;
  background-color: #F2F3F6;
  flex-shrink: 0;
}
.reviews-search {
  display: flex;
  align-items: center;
  gap: 9px;
  border-radius: 16px;
  background-color: #F5F6F9;
  padding: 12px;
}
.magnifer {
  font-size: 24px;
}
.reviews-input {
  max-width: 100%;
  flex-grow: 1;
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  &::placeholder {
    color: #878FA2;
  }
}
.filters-container {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 400px) {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    &::-webkit-scrollbar { /* WebKit */
      width: 0;
      height: 0;
    }
    & * {
      flex-shrink: 0;
    }
  }
}
.reviews {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.show-more {
  width: 260px;
  padding: 14px 16px;
  border-radius: 15px;
  background-color: #F2F3F6;
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.doctor-send-review-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
}
.doctor-send-review {
  display: flex;
  align-items: center;
  gap: 8px;
}
.doctor-send-review-text-container {
  display: flex;
  flex-direction: column;
}
.icon-doctor-like {
  font-size: 32px;
  margin: 0;
}
.doctor-recommendation-text {
  color: #262633;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.doctor-reviews-count {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
</style>
