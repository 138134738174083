const buildSearchParams = (params) => {
  const queryParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];

    if (Array.isArray(value)) {
      value.forEach((item) => queryParams.append(`${key}[]`, item));
    } else {
      queryParams.append(key, value);
    }
  });

  return queryParams.toString();
};
const buildGetURL = (baseURL, params) => {
  const searchParams = (params && Object.keys(params).length > 0) ? buildSearchParams(params) : '';
  const url = new URL(baseURL);
  url.search = searchParams;
  return url.toString();
};

export default buildGetURL;
