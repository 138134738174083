<script setup lang="ts">
  import EcButton from '~/UI/Buttons/EcButton.vue';
  import Skeleton from '~/UI/Skeletons/DoctorCardSkeleton.vue';

  defineProps({
    pending: Boolean
  });

  const page = defineModel('modelValue', {
    type: Number,
    required: true
  });

  const doctorsStore = useDoctorsStore();
  const { doctorsCacheMap } = storeToRefs(doctorsStore);

  const { currentClinic } = storeToRefs(useClinicsStore());
</script>

<template>
  <div class="scroll-normalize">
    <div v-if="pending && doctorsCacheMap.size <= 1" class="doctor-cards-wrapper">
      <div class="main-container">
        <Skeleton v-for="item in 10" :key="item" />
      </div>
    </div>
    <div v-else class="doctor-cards-wrapper">
      <div v-for="doctor in doctorsCacheMap.values()" :key="doctor.id">
        <DoctorListTitle
          :current-group="doctor.group!"
          :previous-group="doctor.prevGroup"
        />
        <div class="doctor-main-container">
          <DoctorItem :doctor="doctor" class="doctor-gap" />
        </div>
      </div>
    </div>
    <!-- <div v-if="useContext() === 'dev'"> -->
      <!-- Если Мытищи на Борисовке -->
      <LazyDoctorContentNewClinicBanner v-if="currentClinic?.id === 2" />
    <!-- </div> -->
    <div v-if="doctorsStore.count > page*10" class="main-container">
      <EcButton
        :loading="pending"
        :disable="pending"
        class="secondary-b full-width more-button"
        @click="page++;"
      >
        Показать еще
      </EcButton>
    </div>
  </div>
</template>

<style scoped lang="scss">
.scroll-normalize {
  scroll-margin-top: 160px;
  @media (max-width: 1023px) {
    scroll-margin-top: 120px;
  }
}
.more-button {
  margin-bottom: 40px;
  background-color: #2D7FF9;
  color: white !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 225%;
  letter-spacing: 0.2px;
  @media (max-width: 1023px) {
    margin-bottom: 24px;
  }
}
.no-doctors {
  margin: 32px auto;
  @media (max-width: 1023px) {
    margin: 24px 0;
  }
}
.other-doctors-group-text {
  color: #171E36;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin: 32px 0;
  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 26px;
    margin: 24px 0;
  }
}
.doctor-cards-wrapper {
  margin: 32px 0;
}
.doctor-gap {
  margin-bottom: 16px;
}
.doctor-main-container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  @media (max-width: 1170px) {
    padding: 0 40px;
  }
  @media (max-width: 1075px) {
    padding: 0 20px;
  }
  @media (max-width: 380px) {
    padding: 0px;
  }
}
</style>
