<script setup lang="ts">
  import type { IDoctor } from '~/EastclinicVueApi';
  import BackLink from '~/UI/BackLink/BackLink.vue';
  import FixedBlock from '~/UI/FixedBlock.vue';
  import Chevrons from '~/UI/Chevrons/index.vue';
  import CarouselWithModal from '~/UI/CarouselWithModal.vue';
  import doctorInfoFavoriteService from '~/utils/doctorInfo/FavoritService';
  import SingleDoctorSkeleton from '~/UI/Skeletons/SingleDoctorSkeleton.vue';

  interface Props {
    pending: boolean
  }

  defineProps<Props>();

  const { currentRoute } = useRouter();

  const doctorsStore = useDoctorsStore();

  const { pageInfo } = storeToRefs(usePageInfoStore());
  const { doctorsCacheMap, doctorInfoEl } = storeToRefs(doctorsStore);

  const doctor = computed<IDoctor|undefined>(() => {
    return doctorsCacheMap.value.values().next().value;
  });

  const desktopScreen = useMediaQuery('(min-width: 1166px)');
  const showGalleryModal = ref(false);

  const favoriteService = computed(() => doctorInfoFavoriteService(doctor.value as IDoctor));

  const firstAward = (awards) => {
    // eslint-disable-next-line no-unreachable-loop
    for (const item in awards) {
      return awards[item];
    }
  };
  const smallImage = (photos) => {
    return (typeof photos === 'object' && '120x120' in photos) ? photos['120x120'][0] : '';
  };
  const openGallery = () => {
    showGalleryModal.value = true;
  };

  const diseases = computed(() => {
    if (pageInfo.value?.doc_specialication_on) {
      return pageInfo.value.doc_specialication_on.replace(/Cпециализируется на диагностике и лечении:/g, '');
    } else {
      return '';
    }
  });

  // todo перенести в карусель когда весь контент будет однородным
  const diploms = computed(() => {
    const items = [];
    for (const item in doctor.value?.diploms_1) {
      if (doctor.value?.diploms_1[item]) {
        for (const c in doctor.value?.diploms_1[item].content) {
          const content = doctor.value?.diploms_1[item].content[c];
          if (content.replicas) {
            for (const r in content.replicas) {
              const replica = content.replicas[r];
              if (replica.quality === '720p') {
                items.push(replica);
              }
            }
          } else if (content.type === '720p') {
            items.push(content);
          }
        }
      }
    }
    return items;
  });

  const { schedulesByDoctorId } = storeToRefs(useDevScheduleStore());

  const devSchedules = computed(() => {
    if (doctor.value) {
      return schedulesByDoctorId.value.get(doctor.value.id) ?? [];
    }
    return [];
  });

  const operatorLinkText = refAutoReset('Ссылка на страницу врача', 1500);

  const copyLink = () => {
    const path = 'eastclinic.ru' + currentRoute.value.path;
    window.prompt('Скопировать: Ctrl+C, Enter', path);
    // Не работает без https
    // navigator.clipboard.writeText(path);
    operatorLinkText.value = 'Скопировано!';
  };

  const accordionTitle = computed(() => {
    if (!pageInfo.value) {
      return 'Врач о себе';
    }
    if (pageInfo.value.type === 'cabinet') {
      return 'Центр о себе';
    }
    if (doctor.value && (doctor.value.main_specials?.[0] as unknown as string) === 'Медсестра') {
      return 'Медсестра о себе';
    }
    return 'Врач о себе';
  });
</script>

<template>
  <LazyDoctorSingleAppointmentBanner
    v-if="doctor?.id"
    :doctor-id="doctor.id"
  />
  <div v-if="pending" class="doctor-cards-wrapper">
    <div class="main-container">
      <SingleDoctorSkeleton />
    </div>
  </div>

  <div v-else-if="doctor" class="main-container">
    <BackLink />
    <div itemscope itemtype="http://schema.org/Physician">
      <div class="single-doctor">
        <div class="single-doctor__main">
          <meta itemprop="url" :content="doctor.uri">
          <div ref="doctorInfoEl">
            <DoctorSingleSlider :doctor="doctor" @open-gallery="openGallery" />
            <div class="single-doctor__info">
              <div class="doctor__top__info__desc desc">
                <h1 class="single-doctor-full-name" itemprop="name">
                  {{ doctor.fullname }}
                </h1>
                <div
                  class="doctor__top__info__desc_specials"
                  itemprop="medicalSpecialty"
                >
                  <DoctorSingleSpecialsList :specials="doctor.main_specials" />
                </div>
                <meta itemProp="description" :content="doctor.description">
              <!-- <div v-if="doctor.specialsSchemaOrg">
                <div v-for="(specUrl, i) in doctor.specialsSchemaOrg" :key="i">
                  <meta :content="specUrl" itemProp="MedicalSpecialty">
                </div>
              </div> -->
              </div>
            </div>
          </div>
          <ClientOnly>
            <div
              v-if="useContext() === 'operator'"
              class="operator-doctor-url"
              @click="copyLink"
            >
              <IconLink filled style="font-size: 20px; margin: 0" />
              <span>{{ operatorLinkText }}</span>
            </div>
          </ClientOnly>
          <ClientOnly>
            <LazyOperatorEditor
              v-if="useContext() === 'operator'"
              :commentable-id="doctor.id"
              :comment-body="doctorsStore.tryParseComment(doctor.id)"
              :comment-id="doctorsStore.doctorsCommentsMap.get(doctor.id)?.id"
              :block-id="doctorsStore.tryParseBlockId(doctor.id)"
            />
          </ClientOnly>
          <DoctorItemExperience
            :experience="doctor?.chevrons?.[0]?.title ?? ''"
            :min-age="doctor?.tv__dop_info_vrach"
            :url="`/${doctor?.uri}`"
            :is-pregnant-patient="doctor?.pregnant === 1"
            type="page"
            style="min-width: 100%; margin-top: 24px;"
          />
          <div v-if="doctor.awards?.length !== 0 || doctor.chevrons?.length !== 0" class="single-doctor__main-info" style="margin-top: 24px">
            <DoctorSingleAwardWithIcon
              v-if="doctor.awards?.length !== 0"
              :award="firstAward(doctor.awards)"
            />
            <Chevrons
              v-if="doctor.chevrons?.length !== 0"
              :chevrons="doctor.chevrons"
            />
          </div>
          <!-- style="max-width: 335px" -->
          <div v-if="!desktopScreen" class="single-doctor__block calendar-block-mobile">
            <hr class="single-doctor-hr 1">
            <div>
              <div class="calendar-top-text">
                Записаться на приём:
              </div>
              <!-- <meta
                itemprop="priceRange"
                :content="'от ' + doctor.minPrice + ' руб.'"
              > -->
              <!-- <FavoriteServiceCard
                :service="favoriteService as ServiceData"
              /> -->
              <DoctorItemService
                v-if="favoriteService"
                :id="doctor.id"
                :favorite-service="favoriteService"
                :services="doctor?.service_data ?? []"
                :is-cabinet="pageInfo?.type === 'cabinet'"
                style="margin-top: 16px; margin-bottom: 16px"
              />
              <DoctorItemCalendar
                :schedules="devSchedules"
                :doctor-id="doctor.id"
                :is-page="true"
                :is-cabinet="pageInfo?.type === 'cabinet'"
                :out-links="doctor.out_links"
              />
              <ClientOnly>
                <LazyOperatorEditor
                  v-if="useContext() === 'operator'"
                  :commentable-id="doctor.id"
                  :comment-body="doctorsStore.tryParseComment(doctor.id)"
                  :doctor-field="2"
                  :comment-id="doctorsStore.doctorsCommentsMap.get(doctor.id)?.id"
                  :block-id="doctorsStore.tryParseBlockId(doctor.id)"
                />
              </ClientOnly>
              <DoctorSingleCalendarBusyBanner :doctor-id="doctor.id" style="margin-top: 24px" />
            </div>
          </div>
          <DoctorSingleAccordion :title="accordionTitle" style="margin-top: 32px">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="content-global-css-wrapper" v-html="doctor.description_full"></span>
            <div v-if="(doctor.interviews ?? []).length > 0">
              <DoctorSingleInterview
                :image="smallImage(doctor)"
                :interview="doctor.interviews!"
              />
            </div>
          </DoctorSingleAccordion>
          <DoctorSingleAccordion v-if="diseases" title="Что лечит">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span class="content-global-css-wrapper" v-html="diseases"></span>
          </DoctorSingleAccordion>
          <DoctorSingleAccordion v-if="doctor.awards?.[1]" title="Награды">
            <div class="single-doctor__block">
              <CarouselWithModal :items="doctor.awards!" />
              <div class="single-doctor__block__footer"></div>
            </div>
          </DoctorSingleAccordion>
          <DoctorSingleAccordion v-if="diploms && pageInfo?.type !== 'cabinet'" title="Образование">
            <CarouselWithModal
              :small="true"
              :items="diploms"
            />
            <DoctorSingleEducation
              :education-desc="pageInfo?.doc_description"
              :education-up="pageInfo?.doc_skillup"
            />
          </DoctorSingleAccordion>

          <div class="single-doctor__block">
            <h2 class="reviews-title-text">
              Отзывы
            </h2>
            <Reviews :doctor-id="doctor.id" />
          </div>
        </div>

        <div v-if="desktopScreen" id="info-tabs" class="single-doctor__aside">
          <FixedBlock>
            <div class="single-doctor__booking">
              <div
                class="calendar-top-text"
              >
                Записаться на приём:
              </div>
              <!-- <meta
                itemprop="priceRange"
                :content="'от ' + doctor.minPrice + ' руб.'"
              > -->
              <DoctorItemService
                v-if="favoriteService"
                :id="doctor.id"
                :favorite-service="favoriteService"
                :services="doctor?.service_data ?? []"
                :is-cabinet="pageInfo?.type === 'cabinet'"
                style="margin-top: 16px; margin-bottom: 16px"
              />
              <DoctorItemCalendar
                :schedules="devSchedules"
                :doctor-id="doctor.id"
                :is-page="true"
                :is-cabinet="pageInfo?.type === 'cabinet'"
                :out-links="doctor.out_links"
              />
              <ClientOnly>
                <LazyOperatorEditor
                  v-if="useContext() === 'operator'"
                  :commentable-id="doctor.id"
                  :comment-body="doctorsStore.tryParseComment(doctor.id)"
                  :doctor-field="2"
                  :comment-id="doctorsStore.doctorsCommentsMap.get(doctor.id)?.id"
                  :block-id="doctorsStore.tryParseBlockId(doctor.id)"
                />
              </ClientOnly>
            </div>
            <DoctorSingleCalendarBusyBanner :doctor-id="doctor.id" style="margin-top: 24px" />
          </FixedBlock>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.single-doctor {
  display: flex;
  &__main {
    max-width: 674px;
    min-width: 566px;
    margin-right: 56px;
    @media (max-width: 1165px) {
      max-width: none;
      width: auto;
      flex-shrink: 1;
    }
    @media (max-width: 1024px) {
      width: 100%;
      max-width: 1100px;
      min-width: 280px;
      margin-right: 0;
    }
  }
  &__aside {
    width: 35%;
    position: relative;
  }
  &__booking {
    box-shadow: 0px 4px 24px 0px rgba(135, 143, 162, 0.25);
    border-radius: 20px;
    border: 1px solid #e5e5e7;
    padding: 24px;
    max-width: 380px;
    /* position: sticky;
    top: 0; */
    &.no-shadow {
      box-shadow: none;
      border: none;
      padding: 0;
    }
    &__header {
      margin-bottom: 24px;
    }
  }
  &__info {
    margin-top: 32px;
    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
  &__add-info {
    margin-top: 18px;
    &__item {
      display: inline-flex;
      padding: 8px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 27px;
      border: 1px solid #d5d7d9;
      @media (max-width: 768px) {
        font-size: 14px;
        /* white-space: nowrap; */
        padding: 6px 12px;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
  }
  &__main-info {
    &__item {
      display: flex;
      align-items: flex-start;
      line-height: 16px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      & div {
        align-self: center;
      }
      & div:not(:last-child) {
        margin-bottom: 4px;
      }
      & img {
        padding-right: 24px;
      }
    }
  }
  &__top-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    gap: 16px;
    @media (max-width: 768px) {
      height: 48px;
    }
    &__left {
      display: flex;
      align-items: center;
      overflow: hidden;
    }
    &__back {
      display: none;
      @media (max-width: 768px) {
        display: inherit;
        margin-right: 20px;
      }
    }
    &__doctor {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-photo {
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        width: 44px;
        height: 44px;
        margin-right: 16px;
        & img {
          width: 100%;
        }
        @media (max-width: 768px) {
          width: 32px;
          height: 32px;
          margin-right: 8px;
        }
      }
      &-phone {
        display: none;
        @media (max-width: 768px) {
          display: inherit;
        }
      }
      &-name {
        font-size: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
      &-info {
        overflow: hidden;
      }
      &-desc {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
  &-hr {
    margin: 32px 0;
  }
  &-ul {
    list-style-type: circle;
    list-style-position: inside;
    margin: 24px 0;
    & li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
.mb-6 {
  padding-bottom: 24px;
}
.calendar-top-text {
  color: #262633;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}
.single-doctor-clinic-map-container {
  display: flex;
  gap: 8px;
}
.single-doctor-full-name {
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-top: 24px;
  margin-bottom: 4px;
  @media (max-width: 767px) {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }
}
.reviews-title-text {
  margin-bottom: 16px;
  margin-top: 32px;
  color: #262633;
  font-family: 'Roboto';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 26px;
  }
}
.calendar-block-mobile {
  max-width: 335px;
  @media (max-width: 1023px) {
    max-width: 404px;
  }
  @media (max-width: 585px) {
    max-width: none;
  }
}
.operator-doctor-url {
  display: inline-flex;
  align-items: end;
  gap: 4px;
  margin-top: 24px;
  cursor: pointer;
  color: #2D7FF9;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  letter-spacing: 0.024px;
}
</style>
