<script setup lang="ts">
  import type { IReview } from '~/EastclinicVueApi';

  const props = defineProps<IReview>();

  // TODO
  // const isLiked = ref(false);

  const colors = ['#F29D61', '#F2C161', '#F28461', '#6CA632', '#5798D9', '#B37DB3', '#50C0E6'];

  const letters = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯABCDEFGHIJKLMNOPQRSTUVWXYZ';

  const reviewDate = useDateFormat(props.published_at, 'DD.MM.YYYY');

  const userColor = computed(() => {
    return props.author
      ? colors[letters.indexOf(props.author[0]?.toUpperCase()) % colors.length]
      : colors[0];
  });
  // TPlatform не соответсвует бэкенду, поэтому switch не учитывает его значения
  const translatePlatform = (source?: string) => {
    switch (source) {
      case undefined: return 'сайте';
      case 'prodoctorov': return 'ПроДокторов';

      case 'EastClinic': return 'сайте';
      case 'YandexMaps': return 'Яндекс картах';
      case 'GoogleMaps': return 'Google картах';
      case 'ProDoctor': return 'ПроДокторов';
      case 'SberHealth': return 'СберЗдоровье';
      case 'NaPopravku': return 'НаПоправку';
    }
  };
  // Этой функции по хорошему не должно быть, но она есть из-за бекенда
  const getIcon = (source?: string) => {
    switch (source) {
      case undefined: return 'EastClinic';
      case 'prodoctorov': return 'ProDoctor';
      default: return source;
    }
  };

  const getLink = computed(() => {
    return props.targets?.[0].out_links.find((l) => l.key === props.source)?.link;
  });
</script>

<template>
  <div class="review-container">
    <div class="review-header">
      <div class="user-review">
        <div class="user-avatar">
          {{ author ? author[0] : '' }}
        </div>
        <div class="review-info-container">
          <span class="user-name">{{ author }}</span>
          <div class="review-info">
            <div class="stars">
              <IconReviewStar
                v-for="star in 5"
                :key="star"
                :style="`color: ${ star <= Number(rating5) ? '#FDB022':'#E8EAF0'}`"
                class="star"
              />
            </div>
            <span class="review-date">{{ reviewDate }}</span>
          </div>
        </div>
      </div>
      <div class="review-platform">
        <component :is="`IconReview${getIcon(source)}`" v-if="source !== 'EastClinic'" filled class="platform-icon" />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.2583 13.5438C12.4492 14.1762 11.5127 14.626 10.5132 14.8622C9.51375 15.0983 8.475 15.1153 7.46834 14.9119C5.96039 14.6742 4.55785 13.9914 3.4406 12.9511C2.32336 11.9108 1.54235 10.5605 1.19775 9.07329C0.853155 7.58611 0.960682 6.0299 1.50654 4.60425C2.0524 3.1786 3.0117 1.94852 4.26141 1.07178C3.46286 1.72154 2.8202 2.54213 2.38074 3.47313C1.94129 4.40413 1.71623 5.42184 1.72213 6.45133C1.72213 9.67468 4.27235 10.5393 5.38876 10.5393C7.03053 10.5393 9.81061 9.02344 11.7807 7.09709C10.9749 7.90015 10.1015 8.63252 9.17031 9.28612C4.78678 12.3344 9.51509 16.0612 13.2583 13.5438Z" fill="url(#paint0_linear_20257_3626)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0781 12.8445L13.7922 13.1404C13.6205 13.2926 13.4375 13.4316 13.2449 13.5563C9.51262 16.0737 4.78431 12.3469 9.16784 9.28221C10.099 8.6286 10.9724 7.89623 11.7783 7.09317C12.1312 6.75126 12.4604 6.38556 12.7633 5.99866L12.9713 5.73597C13.1829 5.45471 13.3767 5.16044 13.5514 4.85489C13.6418 4.69024 13.7222 4.52028 13.7922 4.34594C13.8414 4.22007 13.8797 4.0942 13.918 3.97927C14.1104 3.22912 14.0665 2.43785 13.7922 1.71362C13.8814 1.82519 13.9636 1.94216 14.0384 2.06387C14.0658 2.10765 14.126 2.22257 14.1315 2.23899C15.2698 4.71807 11.4773 8.20958 10.6728 8.99763C10.2769 9.396 9.93808 9.8472 9.66585 10.3384C9.29919 11.1046 9.1569 11.9364 9.57281 12.4891C9.8957 12.916 11.4268 14.4104 14.0781 12.8445Z" fill="url(#paint1_linear_20257_3626)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.044 3.02486C14.0492 3.12329 14.0492 3.22194 14.044 3.32038C14.0478 3.32703 14.0498 3.33458 14.0498 3.34227C14.0498 3.34995 14.0478 3.3575 14.044 3.36416C14.0415 3.45437 14.0305 3.54415 14.0111 3.63231C14.0111 3.73629 13.9674 3.84574 13.94 3.9552C13.9126 4.06465 13.8634 4.19599 13.8141 4.32186C13.7442 4.4962 13.6638 4.66616 13.5733 4.83081C13.3986 5.13636 13.2049 5.43063 12.9932 5.71189L12.7853 5.97458C12.4823 6.36149 12.1513 6.73865 11.7983 7.08056C9.79538 9.03427 7.06097 10.5387 5.38636 10.5387C4.29184 10.5387 1.71972 9.82079 1.71973 6.45069C1.71973 5.42119 1.90151 4.48269 2.37834 3.4725C2.81779 2.5415 3.46045 1.72091 4.25901 1.07114L4.35204 1C3.68986 1.66218 1.16152 4.88006 3.90329 7.61635C4.34279 8.04836 4.8879 8.35757 5.48422 8.51313C6.08054 8.66869 6.70723 8.66517 7.30176 8.50291C10.4485 7.83526 13.8689 4.19052 13.8689 2.28606C13.8662 2.08248 13.861 1.89815 13.7912 1.70687C13.7912 1.70687 13.8173 1.76028 13.8446 1.83143C13.872 1.90257 14.0038 2.1419 13.987 2.22289C14.0277 2.40286 14.0337 2.55467 14.044 2.73891C14.0713 2.82099 14.044 2.92635 14.044 3.02486Z" fill="url(#paint2_linear_20257_3626)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71744 5.2902C7.58983 5.76664 7.33582 6.19972 6.98227 6.54364C6.62872 6.88755 6.18878 7.12951 5.70901 7.24392C5.1147 7.05868 4.61523 6.65049 4.31536 6.10497C4.0155 5.55945 3.93856 4.919 4.10067 4.31797C4.26278 3.71695 4.65136 3.20206 5.18487 2.88133C5.71839 2.5606 6.35539 2.45894 6.96223 2.59769C7.31861 2.9371 7.57686 3.36633 7.70977 3.84019C7.84268 4.31404 7.84533 4.81497 7.71744 5.2902Z" fill="url(#paint3_linear_20257_3626)"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8.65773 5.55346C8.48994 6.16831 8.08524 6.69157 7.53233 7.00855C6.97941 7.32553 6.32338 7.41037 5.70801 7.24449C6.18768 7.13073 6.59117 6.88949 6.94506 6.54628C7.29894 6.20306 7.55353 5.77061 7.68191 5.29464C7.8103 4.81867 7.80771 4.31685 7.67442 3.84223C7.54113 3.36761 7.31864 2.93781 6.96123 2.59827C7.5775 2.7661 8.10212 3.17138 8.42012 3.72531C8.73811 4.27924 8.82356 4.93665 8.65773 5.55346Z" fill="url(#paint4_linear_20257_3626)"></path>
          <defs>
            <linearGradient
              id="paint0_linear_20257_3626"
              x1="5.27931"
              y1="14.2279"
              x2="9.75041"
              y2="3.32101"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint1_linear_20257_3626"
              x1="9.82777"
              y1="13.6386"
              x2="15.126"
              y2="5.57006"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint2_linear_20257_3626"
              x1="6.02629"
              y1="9.97587"
              x2="8.26866"
              y2="1.90725"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint3_linear_20257_3626"
              x1="5.34199"
              y1="6.96619"
              x2="6.93727"
              y2="3.38864"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
            <linearGradient
              id="paint4_linear_20257_3626"
              x1="6.76663"
              y1="7.04844"
              x2="8.60464"
              y2="3.76936"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#1F59EE"></stop>
              <stop offset="0.479167" stop-color="#01B3FF"></stop>
            </linearGradient>
          </defs>
        </svg>
        <NuxtLink v-if="getLink" :to="getLink" external target="_blank" class="review-platform-text">
          Отзыв оставлен на {{ translatePlatform(source) }}
        </NuxtLink>
        <span v-else class="review-platform-text">Отзыв оставлен на {{ translatePlatform(source) }}</span>
      </div>
    </div>
    <!-- TODO
    <div class="review-media-container">
      <div v-for="i in 4" :key="i" class="media"></div>
    </div>
    TODO -->
    <div class="text-with-doctor">
      <ReviewsTextReadFull v-if="text" :text="text" :max-words="50" />
      <!-- TODO
      <ReviewsPageDoctorShortInfo v-if="targets" v-bind="targets" />
      <div class="likes">
        <IconHeartRed v-if="isLiked" filled class="icon-heart" />
        <IconHeart v-else filled class="icon-heart" />
        <span
          v-if="likes>0"
          class="likes-count"
          :style="`color:${isLiked?'#FF3434':'#878FA2'}`"
        >
          {{ likes }}
        </span>
      </div>
      TODO -->
      <div v-if="otvet_legacy">
        <ReviewsReviewAnswer :answer="otvet_legacy" :answer-date="otvet_date_legacy ?? ''" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.review-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.review-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
}
.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: v-bind(userColor);
  border-radius: 999px;
  color: white;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.user-review {
  display: flex;
  align-items: center;
  gap: 10px;
}
.review-info-container {
  display: flex;
  flex-direction: column;
}
.user-name {
  color: #262633;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}
.review-info {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}
.star {
  font-size: 16px;
  margin: 0;
}
.review-date {
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.review-platform {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 8px;
  border-radius: 18px;
  background-color: #F2F3F6;
}
.review-platform-text {
  color: #262633 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-decoration: none;
}
.platform-icon {
  font-size: 14px;
  margin: 0;
}
.review-media-container {
  display: flex;
  gap: 8px;
}
.media {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  background-color: #f2f3f6;
}
.text-with-doctor {
  display: flex;
  flex-direction: column;
  gap: 14px;
  overflow: hidden;
}
.likes {
  display: flex;
  align-items: center;
  gap: 4px;
  /* margin-bottom: 12px; */
}
.icon-heart {
  font-size: 24px;
  margin: 0;
  cursor: pointer;
}
.likes-count {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
</style>
