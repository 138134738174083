<script setup lang="ts">
  interface Props {
    schedules: Schedule[]
    doctorId: number
    isPage?: boolean
    isCabinet?: boolean
    isBooking?: boolean
    // это из IDoctor
    outLinks?: {key: string, link: string}[]|null
  }

  const props = defineProps<Props>();

  const { currentRoute } = useRouter();

  const bookingStore = useBookingStore();

  const clinicsStore = useClinicsStore();

  const devScheduleStore = useDevScheduleStore();

  const clinicDropdownRef = ref();

  const currentSlide = ref(0);

  const showMoreClinics = ref(false);

  const showMoreSlots = ref(false);

  const showBannerYandex = computed(() => {
    if (!currentRoute.value.query.utm_selected_clinic_id) {
      return false;
    }
    if (yandexClinicId.value) {
      return false;
    }
    return true;
  });

  const yandexClinicId = computed(() => {
    const id = currentRoute.value.query.utm_selected_clinic_id;
    if (!id) {
      return undefined;
    }
    return props.schedules.find((s) => s.clinicId === Number(id))?.clinicId;
  });

  const isSlotButton = computed(() => {
    if (showMoreSlots.value) {
      return false;
    }
    return (currentDay.value?.slots.length ?? 0) > 10;
  });

  const userSelectedClinicId = ref(-1);

  const manyClinics = computed(() => {
    return props.schedules.length > 1 && isTelemedDoctor.value === false;
  });

  const currentClinic = computed(() => {
    if (userSelectedClinicId.value !== -1) {
      return clinicsStore.getClinic(userSelectedClinicId.value)!;
    }
    if (clinicsStore.currentClinic) {
      const currentClinicId = clinicsStore.currentClinic.id;
      const schedulesHasCurrentClinic = props.schedules.some((s) => s.clinicId === currentClinicId);
      return schedulesHasCurrentClinic ? clinicsStore.currentClinic : clinicsStore.getClinic(props.schedules?.[0].clinicId);
    }
    return clinicsStore.getClinic(props.schedules?.[0].clinicId);
  });

  const currentSchedule = computed(() => {
    return props.schedules.find((s) => s.clinicId === currentClinic.value?.id);
  });

  const clickOnDropdown = () => {
    if (manyClinics.value === false) {
      return;
    }
    showMoreClinics.value = !showMoreClinics.value;
  };
  const clickOnClinic = (id: number) => {
    showMoreClinics.value = false;
    userSelectedDay.value = null;
    userSelectedClinicId.value = id;
    scrollCarousel();
  };

  const move = (direction: 'left'|'right') => {
    const nextIndex = direction === 'left' ? currentSlide.value - 1 : currentSlide.value + 1;
    if (nextIndex === -1 || nextIndex === 6) {
      return;
    }
    currentSlide.value = nextIndex;
  };

  onClickOutside(clinicDropdownRef, () => {
    showMoreClinics.value = false;
  });

  const isDifferentMonth = (month: string, day: number) => {
    return day === 1 && currentDay.value?.month !== month && currentDay.value?.day !== 1;
  };

  const userSelectedDay = ref<Day|null>(null);

  const currentDay = computed(() => {
    if (userSelectedDay.value !== null) {
      return userSelectedDay.value;
    }
    for (const day of currentSchedule.value?.days ?? []) {
      if (day.slots.length !== 0) {
        return day;
      }
    }
  });

  const clickOnDay = (day: Day) => {
    showMoreSlots.value = false;
    userSelectedDay.value = day;
  };

  const setBookingDataOnClick = (day: number, slot: number) => {
    bookingStore.setBookingData({ doctorId: props.doctorId, timeSlot: slot });
    bookingStore.setBookingFormBlocks({
      showDoctorBlock: true,
      showClinicBlock: true,
      showScheduleBlock: true
    });
    bookingStore.clinic = currentClinic.value;
    if (props.isCabinet) {
      bookingStore.bookingOnlyMessage = true;
    } else {
      bookingStore.bookingOnlyMessage = false;
    }
    bookingStore.setShowModalBooking(true);

    const { onLoaded } = useYandexMetrika();

    onLoaded((ym) => {
      ym.reachGoal('booking');
    });

    devScheduleStore.maybeDeletedSlot = {
      doctorId: props.doctorId,
      clinicId: currentClinic.value?.id ?? 0,
      day,
      timestamp: slot
    };
  };

  const scrollCarousel = () => {
    if (!currentSchedule.value || currentSchedule.value.days.length < 1) {
      devScheduleStore.deleteNearestSlot();
      return;
    }
    const days = currentSchedule.value.days;
    const nearestDayIndex = days.findIndex((d) => d.slots.length > 0);

    if (props.isPage) {
      devScheduleStore.nearestSlot = days[nearestDayIndex].slots[0].timestamp;
      devScheduleStore.clinicForNearestSlot = currentClinic.value;
      devScheduleStore.dayForNearestSlot = days[nearestDayIndex].day;
    }

    currentSlide.value = Math.ceil((nearestDayIndex + 1) / 5) - 1;
  };

  const isTelemedDoctor = computed(() => {
    return props.schedules.some((s) => s.clinicId === 42);
  });

  const clickOnSlot = (day: number, slot: number) => {
    if (isTelemedDoctor.value) {
      const url = props.outLinks?.find((o) => o.key === 'SberHealth');
      if (url) {
        navigateTo(url.link, {
          external: true
        });
      }
      return;
    }
    setBookingDataOnClick(day, slot);
  };

  onMounted(() => {
    setTimeout(() => {
      if (yandexClinicId.value) {
        userSelectedClinicId.value = yandexClinicId.value;
      }
      if (isTelemedDoctor.value) {
        userSelectedClinicId.value = 42;
      }
      scrollCarousel();
    }, 400);
  });
</script>

<template>
  <LazyDoctorItemNoSchedules v-if="showBannerYandex" type="yandex" />
  <div v-else-if="currentSchedule" class="calendar-container">
    <span v-if="manyClinics" class="calendar-text">Врач принимает в нескольких клиниках</span>
    <div v-if="currentClinic" class="clinic-map-container">
      <div ref="clinicDropdownRef" class="clinic-select" @click.stop="clickOnDropdown">
        <div class="clinic-select-text-container">
          <ClinicCircleColor v-if="!isTelemedDoctor" :color="currentClinic.node_color" />
          <span class="clinic-select-main-text">{{ currentClinic.node_address }}</span>
          <IconDirectionsWalk v-if="!isTelemedDoctor" class="icon-walk" filled />
          <span class="clinic-select-secondary-text">{{ clinicGetTextSecondary(currentClinic.way, currentClinic.way_time) }}</span>
        </div>
        <IconChevronDown v-if="manyClinics" :class="`icon-chevron ${showMoreClinics ? 'chevron-up':''}`" />
        <div v-if="showMoreClinics" class="clinicList">
          <DoctorItemCalendarClinicItem
            v-for="schedule in schedules.filter((s) => s.clinicId !== currentClinic?.id)"
            :key="schedule.clinicId"
            :clinic="clinicsStore.getClinic(schedule.clinicId)!"
            @click.stop="clickOnClinic(schedule.clinicId)"
          />
        </div>
      </div>
      <DoctorItemYandexMapButton v-if="!isBooking && !isTelemedDoctor" :clinic="currentClinic" />
    </div>
    <div class="calendar-month">
      {{ currentSchedule.days[currentSlide*5]?.month }}
    </div>
    <div v-if="currentSchedule.days.length > 0" style="position: relative;">
      <Carousel v-model="currentSlide">
        <Slide v-for="week in 6" :key="week">
          <div class="slide-container">
            <div
              v-for="day in currentSchedule?.days.slice((week - 1) * 5, week * 5)"
              :key="day.day"
              :class="`calendar-day-container
                ${isDifferentMonth(day.month, day.day) ? 'day-line':''}
                ${day.slots.length === 0 ? 'no-slots': ''}
                ${day.day === currentDay?.day ? 'active-day':''}
              `"
              @click="clickOnDay(day)"
            >
              <!-- <div v-if="isDifferentMonth(day.month, day.day)" class="newCalendarMonth">
                {{ day.month }}
              </div> -->
              <span class="calendar-day">{{ day.day }}</span>
              <span class="calendar-week">{{ day.weekday }}</span>
            </div>
          </div>
        </Slide>
      </Carousel>
      <div class="chevron-container left" @click="move('left')">
        <IconDoctorCarouselChevron class="calendar-chevron" />
      </div>
      <div class="chevron-container right" @click="move('right')">
        <IconDoctorCarouselChevron class="calendar-chevron chevron-right" />
      </div>
    </div>
    <LazyDoctorItemNoSchedules v-else type="no-days" />
    <div v-if="currentDay && isCabinet === false && isSlotButton === false" class="calendar-slots">
      <div
        v-for="slot in currentDay.slots"
        :key="slot.timestamp"
        class="calendar-slot"
        @click="clickOnSlot(currentDay.day, slot.timestamp)"
      >
        {{ slot.time }}
      </div>
    </div>
    <div v-else-if="currentDay && isCabinet === false && isSlotButton" class="calendar-slots">
      <div
        v-for="slot in currentDay.slots.slice(0, 9)"
        :key="slot.timestamp"
        class="calendar-slot"
        @click="clickOnSlot(currentDay.day, slot.timestamp)"
      >
        {{ slot.time }}
      </div>
      <button type="button" class="calendar-slot" @click="showMoreSlots = true">
        <IconChevronDown style="font-size: 24px; margin: 0" />
      </button>
    </div>
    <div
      v-else-if="currentDay && isCabinet"
      class="calendar-slot"
      style="margin-top: 16px; font-size: 16px;"
      @click="clickOnSlot(currentDay.day, currentDay.slots[0].timestamp)"
    >
      Записаться
    </div>
  </div>
  <LazyDoctorItemNoSchedules v-else type="no-clinics" />
</template>

<style scoped lang="scss">
.carousel__prev {
    left: -15px;
}
.carousel__next {
   right: -15px;
}
.calendar-container {
  display: flex;
  flex-direction: column;
  max-width: 335px;
  @media (max-width: 1023px) {
    max-width: 404px;
  }
  @media (max-width: 585px) {
    max-width: none;
  }
}
.calendar-text {
  color: #666E81;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
  margin-bottom: 8px;
}
.clinic-map-container {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}
.clinic-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  border-radius: 12px;
  background-color: #F2F3F6;
  padding: 8px 12px;
  cursor: pointer;
  user-select: none;
  max-width: calc(100% - 48px);
}
.clinic-select-text-container {
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-walk {
  font-size: 14px;
  min-width: 14px;
  margin: 0;
}
.clinic-select-main-text {
  margin-left: 4px;
  margin-right: 4px;
  color: #171E36;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.clinic-select-secondary-text {
  margin-left: 2px;
  color: #878FA2;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* @media (max-width: 420px) {
    text-wrap: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  } */
}
.icon-chevron {
  font-size: 24px;
  min-width: 24px;
  margin: 0;
  color: #6B7280;
}
.chevron-up {
  transform: rotate(180deg);
}

.calendar-slots {
  display: grid;
  grid-template-columns: repeat(5, minmax(40px, 1fr));
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; */
  gap: 8px;
  margin-top: 16px;
}
.calendar-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  border-radius: 12px;
  padding: 8px 16px;
  background-color: #2D7FF9;
  min-height: 38px;
  max-height: 38px;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}
.calendar-month {
  margin-left: 41px;
  margin-bottom: 4px;
  color: #878FA2;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.slide-container {
  display: flex;
  justify-content: space-around;
  gap: 8px;
  width: calc(100% - 56px);
}
.calendar-day-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  padding: 8px 10px;
  user-select: none;
  &:hover:not(.no-slots) {
    cursor: pointer;
    background-color: #ECF2FF;
    .calendar-day, .calendar-week {
      color: #2D7FF9;
    }
  }
  & + .day-line::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -10px;
    border-left: 1px solid #AAAFBB;
    @media (max-width: 350px) {
      left: -5px;
    }
  }
}
.no-slots {
  pointer-events: none;
  .calendar-day, .calendar-week {
    color: #AAAFBB;
  }
}
.active-day {
  background-color: #ECF2FF;
  .calendar-day, .calendar-week {
    color: #2D7FF9;
  }
  .calendar-day {
    font-weight: 600;
  }
}
.calendar-day {
  color: #171E36;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.calendar-week {
  color: #262633;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.048px;
}
.calendar-chevron {
  font-size: 20px;
  color: #171E36;
  margin: 0;
}
.chevron-right {
  transform: rotate(180deg);
}
.chevron-container {
  position: absolute;
  top: 0;
  background-color: white;
  height: 100%;
  padding: 17px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.clinicList {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 4px;
  top: 100%;
  left: 0px;
  z-index: 10;
  width: 100%;
  margin-top: 4px;
  padding: 4px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0px 4px 24px 0px rgba(103, 109, 122, 0.15);
}
.newCalendarMonth {
  position: absolute;
  top: -18px;
  left: 0px;
  color: #878FA2;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
</style>
